import { FlightDirectionEnum } from 'app/enums/flight-direction.enum';
import React, { Dispatch, FC, ReactNode, SetStateAction } from 'react';

type IFlightTabNav = Record<FlightDirectionEnum, ReactNode> & {
  activeFlightDirection: FlightDirectionEnum;
  setActiveFlightDirection: Dispatch<SetStateAction<FlightDirectionEnum>>;
};

const FlightTabNav: FC<IFlightTabNav> = (props) => {
  const { activeFlightDirection, setActiveFlightDirection, OUTBOUND, INBOUND } =
    props;

  return (
    <ul className='pt-4 flex text-base font-normal  mx-10 justify-between sm:justify-start gap-4  overflow-auto  no-scrollbar'>
      <li className='tab-item'>
        <button
          className={` nav-tab  ${
            activeFlightDirection === FlightDirectionEnum.OUTBOUND
              ? 'font-bold cursor-default text-primary underline underline-offset-4'
              : ''
          }`}
          onClick={() => setActiveFlightDirection(FlightDirectionEnum.OUTBOUND)}
        >
          {OUTBOUND}
        </button>
      </li>

      <li className='tab-item'>
        <button
          className={` nav-tab  ${
            activeFlightDirection === FlightDirectionEnum.INBOUND
              ? 'font-bold cursor-default text-primary underline underline-offset-4'
              : ''
          }`}
          onClick={() => setActiveFlightDirection(FlightDirectionEnum.INBOUND)}
        >
          {INBOUND}
        </button>
      </li>
    </ul>
  );
};

export default FlightTabNav;
