import { AmendmentStatusEnum } from 'app/enums/amendment.status.enum';
import { SubAgencyUserStatusEnum } from 'app/enums/sub-agency-user-status';
import moment from 'moment';

export const getGenderFromTitle = (title: string) => {
  if (['Ms', 'Mrs'].includes(title)) return 'Female';
  else return 'Male';
};

export const getInitials = (name: string) => {
  const arr = name.split(' ');

  let initials = arr[0].substring(0, 1);

  if (arr.length > 1) {
    initials += arr[arr.length - 1].substring(0, 1);
  }

  return initials.toUpperCase();
};

export const formatDOB = (dob: string, format: string) => {
  return moment(dob, format).format('DD MMM YYYY');
};

export const getAmendmentStatusColor = (status: AmendmentStatusEnum) => {
  switch (status) {
    case AmendmentStatusEnum.PENDING:
      return 'yellow';
    case AmendmentStatusEnum.REJECTED_BY_SUB_AGENCY:
      return 'yellow';
    case AmendmentStatusEnum.REJECTED:
      return 'red';
    case AmendmentStatusEnum.COMPLETED:
      return 'green';
    case AmendmentStatusEnum.AWAITING_SUB_AGENCY_APPROVAL:
      return 'yellow';
    case AmendmentStatusEnum.APPROVED_BY_SUB_AGENCY:
      return 'yellow';
    case AmendmentStatusEnum.AWAITING_AGENCY_APPROVAL:
      return 'yellow';
    default:
      assertUnreachable(status);
      return '';
  }
};

export const getAmendmentStatusText = (status: AmendmentStatusEnum) => {
  switch (status) {
    case AmendmentStatusEnum.PENDING:
      return 'Pending';
    case AmendmentStatusEnum.COMPLETED:
      return 'Completed';
    case AmendmentStatusEnum.AWAITING_AGENCY_APPROVAL:
      return 'Awaiting Agency approval';
    case AmendmentStatusEnum.APPROVED_BY_SUB_AGENCY:
      return `Approved by Sub Agency`;
    case AmendmentStatusEnum.AWAITING_SUB_AGENCY_APPROVAL:
      return `Awaiting Sub Agency approval`;
    case AmendmentStatusEnum.REJECTED_BY_SUB_AGENCY: // <<<<<<<<<<<<<<<<<<<<<<<< Added New Status
      return `Rejected by Sub Agency`;
    case AmendmentStatusEnum.REJECTED:
      return 'Rejected';
    default:
      assertUnreachable(status);
      return '';
  }
};

export const getRefundTypeBackgroundColor = (status: string) => {
  switch (status) {
    case 'REFUNDABLE':
      return 'bg-success';
    case 'NON_REFUNDABLE':
      return 'bg-danger';
    case 'PARTIAL_REFUNDABLE':
      return 'bg-yellow-400';
    default:
      return 'bg-slate-500';
  }
};

export const mapBooleanToSubAgencyUserStatus = (
  statusInBoolean: boolean,
): SubAgencyUserStatusEnum => {
  return statusInBoolean
    ? SubAgencyUserStatusEnum.ENABLED
    : SubAgencyUserStatusEnum.DISABLED;
};

export const mapSubAgencyUserStatusToBoolean = (
  status: SubAgencyUserStatusEnum,
): boolean => {
  return status === SubAgencyUserStatusEnum.ENABLED ? true : false;
};

export const isValidDate = (date: string, format: string = 'YYYYMMDD') =>
  moment(date, format, true).isValid();

export const getMiddleIndexOfArray = (arr: Array<any>): number | null => {
  if (arr.length === 0) return null;
  return Math.ceil(arr.length / 2) - 1;
};

// Utility function for exhaustiveness checking
export const assertUnreachable = (x: never): never => {
  throw new Error(`Unhandled case: ${x}`);
};
