import React, { FC, useMemo } from 'react';
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useMutation, useQuery } from '@tanstack/react-query';
import http from 'app/config/http';
import { ISeriesInventoryForm } from 'app/pages/SeriesInventory/Pages/SeriesInventoryEdit/types';
import { toast } from 'react-toastify';
import AirportSearchField from 'app/components/AirportSearchField/AirportSearchField';
import { IAirline, IAirport } from 'app/types';
import AirlineSearchField from 'app/components/AirlineSearchField/AirlineSearchField';
import LoaderButton from 'app/components/LoaderButton/LoaderButton';
import { CalendarIcon, EditPencilIcon } from 'app/icons';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from 'app/utils/currency-utils';
import PageLoader from 'app/components/PageLoader/PageLoader';
import { DatePicker } from 'app/library/datepicker';
import { EXEMPT_PNR } from 'app/config';

const InventoryFormSchema = Yup.object({
  from: Yup.object().nullable().required('This field is required'),
  to: Yup.object().nullable().required('This field is required'),
  departureDate: Yup.string().required('This field is required'),
  departureTime: Yup.string().required('This field is required'),
  arrivalDate: Yup.string()
    .required('This field is required')
    .test(
      'is-arrival-date-after-departure-date',
      'Arrival date should be after departure date',
      function (arrivalDate) {
        const { departureDate } = this.parent;

        const arrival = moment(arrivalDate, 'YYYY-MM-DD');
        const departure = moment(departureDate, 'YYYY-MM-DD');

        return arrival.isSameOrAfter(departure);
      },
    ),
  arrivalTime: Yup.string()
    .required('This field is required')
    .test(
      'is-arrival-time-after-departure-time',
      'Arrival time should be after departure time',
      function (arrivalTime) {
        const { departureDate, departureTime, arrivalDate } = this.parent;

        const arrival = moment(
          `${arrivalDate}${arrivalTime}`,
          'YYYY-MM-DDHH:mm',
        );
        const departure = moment(
          `${departureDate}${departureTime}`,
          'YYYY-MM-DDHH:mm',
        );

        return arrival.isAfter(departure);
      },
    ),
  flight: Yup.object().nullable().required('This field is required'),
  flightNumber: Yup.string().trim().required('This field is required'),
  // pnr: Yup.string().trim().required('This field is required'),
  pnr: Yup.string().when('fareIdentifier', {
    is: function (fareIdentifier: string) {
      return fareIdentifier === EXEMPT_PNR;
    },
    then: Yup.string().trim(),
    otherwise: Yup.string().trim().required('This field is required'),
  }),
  costPrice: Yup.string().required('This field is required'),
  tax: Yup.string().trim().required('This field is required'),
  fareIdentifier: Yup.string().trim().required('This field is required'),
  isDirectFlight: Yup.string().trim().required('This field is required'),
  isLiveOnNetwork: Yup.string().trim().required('This field is required'),
  fareRules: Yup.string().trim().required('This field is required'),
  baggage: Yup.object({
    checkIn: Yup.string().trim().required('This field is required'),
    cabin: Yup.string().trim().required('This field is required'),
  }),
  infant: Yup.object({
    costPrice: Yup.number()
      .required('This field is required')
      .typeError('You must specify a number'),
    tax: Yup.number()
      .required('This field is required')
      .typeError('You must specify a number'),
  }),
  stopsOverAirport: Yup.array().of(
    Yup.object().nullable().required('This field is required'),
  ),
});

interface SeriesInventoryEditFormProps {
  ticket: any;
}

const SeriesInventoryEditForm: FC<SeriesInventoryEditFormProps> = (props) => {
  const { ticket } = props;
  const navigate = useNavigate();

  const fareIdenfierQuery = useQuery(['fareIdenfiers'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-tickets/fare-identifiers`,
    );

    return data;
  });

  const mutation = useMutation(async (payload: any) => {
    const { data } = await http.put(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/series-tickets/${ticket._id}`,
      payload,
    );

    return data;
  });

  const initialValues: ISeriesInventoryForm = useMemo(() => {
    if (!ticket)
      return {
        from: null,
        fromTerminal: '',
        to: null,
        toTerminal: '',
        departureDate: '',
        departureTime: '',
        arrivalDate: '',
        arrivalTime: '',
        flight: null,
        flightNumber: '',
        pnr: '',
        costPrice: 0,
        tax: 0,
        fareIdentifier: '',
        isDirectFlight: true,
        isLiveOnNetwork: true,
        fareRules: '',
        baggage: {
          checkIn: '',
          cabin: '',
        },
        infant: {
          costPrice: 0,
          tax: 0,
        },
        stopsOverAirport: [],
      };

    return {
      from: ticket.trip.departure.airport,
      fromTerminal: ticket.trip.departure.airport.terminal,
      to: ticket.trip.arrival.airport,
      toTerminal: ticket.trip.arrival.airport.terminal,
      departureDate: moment(ticket.trip.departure.date, 'YYYYMMDD').format(
        'YYYY-MM-DD',
      ),
      departureTime: moment(ticket.trip.departure.time, 'HHmmss').format(
        'HH:mm',
      ),
      arrivalDate: moment(ticket.trip.arrival.date, 'YYYYMMDD').format(
        'YYYY-MM-DD',
      ),
      arrivalTime: moment(ticket.trip.arrival.time, 'HHmmss').format('HH:mm'),
      flight: { code: ticket.flight.code, name: ticket.flight.name },
      flightNumber: ticket.flight.number,
      pnr: ticket.pnr.trim(),
      costPrice: ticket.costPrice,
      tax: ticket.tax,
      fareIdentifier: ticket.fareIdentifier,
      isDirectFlight: true,
      isLiveOnNetwork: ticket.isLiveOnNetwork,
      baggage: {
        checkIn: ticket.baggage.checkIn.replace('Kg', '').trim(),
        cabin: ticket.baggage.cabin.replace('Kg', '').trim(),
      },
      infant: {
        costPrice: ticket.infant.costPrice,
        tax: ticket.infant.tax,
      },
      fareRules: ticket.fareRules,
      stopsOverAirport: ticket.stopsOverAirport,
    };
  }, [ticket]);

  const handleSubmit = async (values: ISeriesInventoryForm) => {
    const payload = {
      departure: {
        date: moment(values.departureDate, 'YYYY-MM-DD').format('YYYYMMDD'),
        time: moment(values.departureTime, 'HH:mm').format('HHmmss'),
        airport: { ...values.from, terminal: values.fromTerminal || undefined },
      },
      arrival: {
        date: moment(values.arrivalDate, 'YYYY-MM-DD').format('YYYYMMDD'),
        time: moment(values.arrivalTime, 'HH:mm').format('HHmmss'),
        airport: { ...values.to, terminal: values.toTerminal || undefined },
      },
      flight: {
        name: values.flight.name,
        code: values.flight.code,
        number: values.flightNumber,
      },
      costPrice: +values.costPrice,
      tax: +values.tax,
      fareIdentifier: values.fareIdentifier,
      pnr:
        values.fareIdentifier === 'OFFER_FARE_WITHOUT_PNR'
          ? ''
          : values.pnr.trim(),
      isLiveOnNetwork: values.isLiveOnNetwork,
      isDirectFlight: values.isDirectFlight,
      baggage: {
        checkIn: values.baggage.checkIn.trim() + ' Kg',
        cabin: values.baggage.cabin.trim() + ' Kg',
      },
      fareRules: values.fareRules,
      infant: {
        costPrice: values.infant.costPrice,
        tax: values.infant.tax,
      },
      stopsOverAirport: values.stopsOverAirport,
    };

    try {
      await mutation.mutateAsync(payload);

      toast.success('Series Inventory updated successfully.');
    } catch (ex: any) {
      toast.error(ex?.data?.message || 'Some error occured, please try again.');
      console.log(ex);
    }
  };

  if (fareIdenfierQuery.isLoading) return <PageLoader />;

  const fareIdenfiers = fareIdenfierQuery.data;

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={InventoryFormSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          setFieldValue,
          errors,
        }: FormikProps<ISeriesInventoryForm>) => (
          <Form>
            <div className='card px-8 py-7 mb-4'>
              <div className='flex justify-between items-center'>
                <div className='text-base font-bold '>Fight Details</div>
                <div className='flex gap-4 items-end'>
                  {/* <div className="text-base font-bold text-slate-9 00">
                                        Markup: <span className="text-black">{formatCurrency(ticket.markup)}</span>{' '}
                                    </div> */}
                  <div>
                    <label className='text-xs text-slate-700'>Markup</label>
                    <div className='font-bold py-0 '>
                      {formatCurrency(ticket.markup)}
                    </div>
                  </div>
                  <div
                    className='cursor-pointer pb-1'
                    onClick={() =>
                      navigate(
                        `/my-series/series-inventory/${ticket._id}/revise-markup`,
                      )
                    }
                  >
                    <EditPencilIcon />
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-2 gap-6 mt-8'>
                <div className='col-span-2 sm:col-span-1'>
                  <label className='text-xs mb-2 label-required' htmlFor='from'>
                    FROM
                  </label>
                  <div className='flex flex-col gap-2  sm:flex-row sm:gap-0'>
                    <AirportSearchField
                      id='from'
                      className='form-control rounded-r-none'
                      placeholder={'Select From Airport'}
                      selectedAirport={values.from}
                      onSelectedAirportChange={(airport: IAirport) =>
                        setFieldValue('from', airport)
                      }
                    />
                    <div>
                      <Field
                        type='text'
                        id='fromTerminal'
                        name='fromTerminal'
                        className='form-control rounded-l-none'
                        placeholder='Terminal'
                      />
                    </div>
                  </div>
                  <div className='form-error'>
                    <ErrorMessage name='from' />
                  </div>
                </div>
                <div className='col-span-2 sm:col-span-1'>
                  <label className='text-xs mb-2 label-required' htmlFor='to'>
                    TO
                  </label>
                  <div className='flex flex-col gap-2  sm:flex-row sm:gap-0'>
                    <AirportSearchField
                      id='to'
                      className='form-control rounded-r-none'
                      placeholder={'Select From Airport'}
                      selectedAirport={values.to}
                      onSelectedAirportChange={(airport: IAirport) =>
                        setFieldValue('to', airport)
                      }
                    />
                    <div>
                      <Field
                        type='text'
                        id='toTerminal'
                        name='toTerminal'
                        className='form-control rounded-l-none'
                        placeholder='Terminal'
                      />
                    </div>
                  </div>
                  <div className='form-error'>
                    <ErrorMessage name='to' />
                  </div>
                </div>
              </div>

              <div className='grid grid-cols-3 gap-6 mt-8'>
                <div className='col-span-3  sm:col-span-2 xl:col-span-1'>
                  {/* <div className="font-semibold">Stops</div> */}
                  <label className='text-xs mb-2' htmlFor='from'>
                    STOPS
                  </label>
                  <FieldArray
                    name='stopsOverAirport'
                    render={(arrayHelpers) => (
                      <div>
                        {values.stopsOverAirport.map((_, index) => (
                          <div
                            key={index}
                            className='flex gap-4 items-center mt-2'
                          >
                            <div className='w-full'>
                              <div className='flex gap-2 items-center'>
                                <AirportSearchField
                                  id='index'
                                  className='form-control '
                                  placeholder={'Select Airport'}
                                  selectedAirport={
                                    values.stopsOverAirport[index]
                                  }
                                  onSelectedAirportChange={(
                                    airport: IAirport,
                                  ) =>
                                    setFieldValue(
                                      `stopsOverAirport[${index}]`,
                                      airport,
                                    )
                                  }
                                />
                                <button
                                  type='button'
                                  className='text-3xl text-red-500'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  &times;
                                </button>
                              </div>

                              <div className='form-error'>
                                <ErrorMessage
                                  name={`stopsOverAirport[${index}]`}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                        <button
                          type='button'
                          className='text-sm text-blue-500 mt-4'
                          onClick={() => arrayHelpers.push(null)}
                        >
                          + Add Stop
                        </button>
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className='grid grid-cols-2 gap-2 sm:gap-6 mt-8'>
                <div className='col-span-2 sm:col-span-1'>
                  <label className='text-xs mb-2 ' htmlFor='departureDate'>
                    DEPART ON
                  </label>

                  <div className='relative flex items-center'>
                    <div className='absolute z-10 right-4'>
                      <CalendarIcon />
                    </div>
                    <DatePicker
                      className='form-control'
                      onChange={(val) => {
                        setFieldValue(
                          'departureDate',
                          moment(val).format('YYYY-MM-DD'),
                        );
                      }}
                      value={moment(
                        values.departureDate,
                        'YYYY-MM-DD',
                      ).toDate()}
                      minDate={new Date()}
                      format={'DD/MM/YYYY'}
                    />
                  </div>
                  <div className='form-error'>
                    <ErrorMessage name='departureDate' />
                  </div>
                </div>
                <div className='col-span-2 sm:col-span-1'>
                  <label className='text-xs mb-2 ' htmlFor='departureTime'>
                    DEPARTURE TIME
                  </label>
                  <Field
                    type='time'
                    id='departureTime'
                    name='departureTime'
                    className='form-control'
                  />
                  <div className='form-error'>
                    <ErrorMessage name='departureTime' />
                  </div>
                </div>
                <div className='col-span-2 sm:col-span-1'>
                  <label className='text-xs mb-2 ' htmlFor='arrivalDate'>
                    ARRIVES ON
                  </label>
                  {/* <Field type="date" id="arrivalDate" name="arrivalDate" className="form-control" /> */}
                  <div className='relative flex items-center'>
                    <div className='absolute z-10 right-4'>
                      <CalendarIcon />
                    </div>
                    <DatePicker
                      className='form-control'
                      // onChange={(val) => setFieldValue('fromDate', val)}
                      onChange={(val) => {
                        setFieldValue(
                          'arrivalDate',
                          moment(val).format('YYYY-MM-DD'),
                        );
                      }}
                      value={moment(values.arrivalDate, 'YYYY-MM-DD').toDate()}
                      minDate={new Date()}
                      format={'DD/MM/YYYY'}
                    />
                  </div>
                  <div className='form-error'>
                    <ErrorMessage name='arrivalDate' />
                  </div>
                </div>
                <div className='col-span-2 sm:col-span-1'>
                  <label className='text-xs mb-2 ' htmlFor='arrivalTime'>
                    ARRIVAL TIME
                  </label>
                  <Field
                    type='time'
                    id='arrivalTime'
                    name='arrivalTime'
                    className='form-control'
                  />
                  <div className='form-error'>
                    <ErrorMessage name='arrivalTime' />
                  </div>
                </div>
              </div>

              <div className='grid grid-cols-3 gap-2 sm:gap-6 mt-6'>
                <div className='col-span-3 sm:col-span-1'>
                  <label
                    className='text-xs mb-2 label-required'
                    htmlFor='flight'
                  >
                    SELECT FLIGHT
                  </label>
                  <AirlineSearchField
                    id='flight'
                    className='form-control'
                    placeholder={'Select Flight'}
                    selectedAirline={values.flight as IAirline}
                    onSelectedAirlineChange={(airline: IAirline) =>
                      setFieldValue('flight', airline)
                    }
                  />
                  <div className='form-error'>
                    <ErrorMessage name='flight' />
                  </div>
                </div>
                <div className='col-span-3 sm:col-span-1'>
                  <label
                    className='text-xs mb-2 label-required'
                    htmlFor='flightNumber'
                  >
                    ENTER FLIGHT NUMBER
                  </label>
                  <Field
                    type='text'
                    id='flightNumber'
                    name='flightNumber'
                    className='form-control'
                  />
                  <div className='form-error'>
                    <ErrorMessage name='flightNumber' />
                  </div>
                </div>
                <div className='col-span-3 sm:col-span-1'>
                  <label className='text-xs mb-2 label-required' htmlFor='pnr'>
                    ENTER AIRLINE PNR
                  </label>
                  <Field
                    type='text'
                    id='pnr'
                    name='pnr'
                    className='form-control'
                    disabled={
                      values.fareIdentifier === 'OFFER_FARE_WITHOUT_PNR'
                    }
                  />
                  <div className='form-error'>
                    <ErrorMessage name='pnr' />
                  </div>
                </div>
              </div>

              <div className='grid grid-cols-9 gap-6 mt-6'>
                <div className='col-span-9 sm:col-span-3 xl:col-span-2'>
                  <label className='text-xs mb-2 ' htmlFor='costPrice'>
                    COST PRICE PER SEAT
                  </label>
                  <div className='input-group flex items-center'>
                    <div className='input-group-prepend'>₹</div>
                    <Field
                      type='text'
                      id='costPrice'
                      name='costPrice'
                      className='form-control pl-8'
                    />
                  </div>
                  <div className='form-error'>
                    <ErrorMessage name='costPrice' />
                  </div>
                </div>
                <div className='col-span-9 sm:col-span-3 xl:col-span-2'>
                  <label className='text-xs mb-2 ' htmlFor='taxPerSeat'>
                    TAXES PER SEAT
                  </label>
                  <div className='input-group flex items-center'>
                    <div className='input-group-prepend'>₹</div>
                    <Field
                      type='text'
                      id='taxPerSeat'
                      name='tax'
                      className='form-control pl-8'
                    />
                  </div>
                  <div className='form-error'>
                    <ErrorMessage name='tax' />
                  </div>
                </div>
                <div className='col-span-9 sm:col-span-3 xl:col-span-2'>
                  <label
                    className='text-xs mb-2 label-required'
                    htmlFor='fareIdentifier'
                  >
                    FARE IDENTIFIER
                  </label>
                  <Field
                    as='select'
                    name='fareIdentifier'
                    placeholder='Mr'
                    className='form-control appearance-none'
                  >
                    {fareIdenfiers.map((val: string) => (
                      <option value={val}>{val}</option>
                    ))}
                  </Field>
                  <div className='form-error'>
                    <ErrorMessage name='fareIdentifier' />
                  </div>
                </div>

                <div className='col-span-9 sm:col-span-3  xl:col-span-2 self-center'>
                  <div className='flex items-center gap-2 mt-4'>
                    <div className='form-switch success'>
                      <Field
                        id='directFlight'
                        name='isDirectFlight'
                        type='checkbox'
                        disabled
                        role='switch'
                      />
                    </div>

                    <label htmlFor='directFlight'>Direct Flight</label>
                  </div>
                </div>
              </div>

              <div className='grid grid-cols-12 gap-6 mt-3'>
                <div className='col-span-12 sm:col-span-8'>
                  <div className='mt-4'>
                    <label className='text-xs mb-2 ' htmlFor='fareRules'>
                      FARE RULES
                    </label>
                    <Field
                      as='textarea'
                      id='name'
                      name='fareRules'
                      className='form-control'
                    />
                    <div className='form-error'>
                      <ErrorMessage name='fareRules' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-12 gap-6 mt-4'>
                <div className='col-span-12 sm:col-span-4'>
                  <label className='text-xs mb-2 ' htmlFor='cabin'>
                    CABIN
                  </label>
                  <div className='input-group relative '>
                    <Field
                      type='text'
                      id='cabin'
                      name='baggage.cabin'
                      className='form-control'
                    />

                    <div className='absolute right-1 top-1 py-1 text-lg font-medium px-3 bg-white'>
                      Kg
                    </div>
                  </div>

                  <div className='form-error'>
                    <ErrorMessage name='baggage.cabin' />
                  </div>
                </div>
                <div className='col-span-12 sm:col-span-4'>
                  <label className='text-xs mb-2 ' htmlFor='checkIn'>
                    CHECK IN
                  </label>
                  <div className='input-group relative'>
                    <Field
                      type='text'
                      id='checkIn'
                      name='baggage.checkIn'
                      className='form-control'
                    />

                    <div className='absolute right-1 top-1 py-1 text-lg font-medium px-3 bg-white'>
                      Kg
                    </div>
                  </div>

                  <div className='form-error'>
                    <ErrorMessage name='baggage.checkIn' />
                  </div>
                </div>
              </div>

              <div className='mt-4'>
                <div className='text-base font-bold mb-8'>
                  Infant Information
                </div>
                <div className='mt-8'>
                  {/* <div className="text-gray-900 font-bold">Baggage Information</div> */}

                  <div className='grid grid-cols-12 gap-6 mt-4'>
                    <div className='col-span-12 sm:col-span-4'>
                      <label
                        className='text-xs mb-2 '
                        htmlFor='infant.costPrice'
                      >
                        COST PRICE
                      </label>
                      <div className='input-group flex items-center'>
                        <div className='input-group-prepend'>₹</div>
                        <Field
                          type='text'
                          id='infant.costPrice'
                          name='infant.costPrice'
                          className='form-control pl-8'
                        />
                      </div>
                      <div className='form-error'>
                        <ErrorMessage name='infant.costPrice' />
                      </div>
                    </div>
                    <div className='col-span-12 sm:col-span-4'>
                      <label className='text-xs mb-2 ' htmlFor='infant.tax'>
                        TAX
                      </label>
                      <div className='input-group flex items-center'>
                        <div className='input-group-prepend'>₹</div>
                        <Field
                          type='text'
                          id='infant.tax'
                          name='infant.tax'
                          className='form-control pl-8'
                        />
                      </div>
                      <div className='form-error'>
                        <ErrorMessage name='infant.tax' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-8'>
                <div className='flex items-center gap-2'>
                  <div className='form-switch success'>
                    <Field
                      id='liveOnNetwork'
                      name='isLiveOnNetwork'
                      type='checkbox'
                    />
                  </div>

                  <label htmlFor='liveOnNetwork'>Live on network</label>
                </div>
              </div>
              <LoaderButton
                type='submit'
                className='bg-primary text-white text-sm font-semibold rounded-lg w-56 mt-12'
                isLoading={mutation.isLoading}
              >
                Save Changes
              </LoaderButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default SeriesInventoryEditForm;
