import Slider from 'rc-slider';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { TPriceRange } from '../../types/flight-filter';
import { formatCurrencyWithOutDecimal } from 'app/utils/currency-utils';

interface IPriceSlider {
  priceRange: TPriceRange;
  value: number;
  setLimitValue: (value: number) => void;
}

export const PriceSlider: FC<IPriceSlider> = (props) => {
  const { setLimitValue, priceRange, value } = props;
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  // Debounced function to handle slider changes
  const handleDebouncedChange = useCallback(
    debounce((newValues) => {
      setLimitValue(newValues);
    }, 100), // Adjust debounce delay as needed
    [setLimitValue],
  );

  return (
    <div className='mt-8'>
      <div className='text-base font-bold py-3'>By Fare</div>
      <Slider
        onChange={(newValues) => {
          setLocalValue(newValues as number);
          handleDebouncedChange(newValues);
        }}
        trackStyle={{
          backgroundColor: 'rgb(var(--primary))',
          height: 10,
          background: 'rgb(var(--primary))',
        }}
        handleStyle={{
          borderColor: 'white',
          backgroundColor: 'white',
          boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.25)',
          height: 20,
          width: 20,
          marginTop: -5,
          opacity: 1,
        }}
        railStyle={{ height: 10 }}
        min={priceRange.min}
        max={priceRange.max}
        value={localValue}
        // step={100}
        allowCross={false}
      />
      <div className='flex justify-between mt-4 text-sm'>
        <span>{formatCurrencyWithOutDecimal(priceRange.min)}</span>
        <span>{formatCurrencyWithOutDecimal(localValue)}</span>
      </div>
    </div>
  );
};
