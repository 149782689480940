import { StopTypeEnum } from 'app/enums';
import { TComboFlightOption } from 'app/modules/ReturnFlights/types/FlightsList';
import { TComboAppliedFilter } from 'app/modules/ReturnFlights/types/combo-applied-filter';

const checkValidStop = (
  selectedStops: Array<StopTypeEnum>,
  FlightStop: StopTypeEnum,
): boolean => {
  if (selectedStops.length === 0) return true;

  console.log('crossed');

  console.log({
    selectedStops,
    FlightStop,
    isTrue: selectedStops.includes(FlightStop),
  });

  return selectedStops.includes(FlightStop);
};

export const filterComboFlightsByStops = (
  comboFlights: Array<TComboFlightOption>,
  filter: Pick<TComboAppliedFilter, 'stops'>,
): Array<TComboFlightOption> => {
  const { stops } = filter;
  if (stops.OUTBOUND.length === 0 && stops.INBOUND.length === 0)
    return [...comboFlights];

  const stopsFilteredFlight: Array<TComboFlightOption> = [];

  comboFlights.forEach((comboFlight) => {
    if (
      checkValidStop(stops.OUTBOUND, comboFlight.OUTBOUND.stops) &&
      checkValidStop(stops.INBOUND, comboFlight.INBOUND.stops)
    ) {
      stopsFilteredFlight.push({ ...comboFlight });
    }
  });

  return stopsFilteredFlight;
};
