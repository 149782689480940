import { FC } from 'react';
import { getFormattedStops } from 'app/utils/flight-utils';

import {
  formatDate,
  formatMinutesToTime,
  formatTime,
} from 'app/utils/date-utils';
import { TFareSupplierSegment } from '../../types/segment.type';

interface IFlightDetails {
  segments: TFareSupplierSegment[];
}

const FlightDetails: FC<IFlightDetails> = (props) => {
  const { segments } = props;
  return (
    <>
      {segments.map((segment, i) => (
        <div key={i}>
          <div className='flex items-center gap-6'>
            <div className='px-4 py-3'>
              <div className='flex items-center gap-2'>
                <img
                  crossOrigin='anonymous'
                  className='w-8 h-8'
                  src={segment.flight.logo}
                  alt=''
                />
                <div className='overflow-hidden'>
                  <div className='text-base font-semibold text-ellipsis whitespace-nowrap overflow-hidden'>
                    {segment.flight.name}
                  </div>
                  <div className='text-xs text-black/70 mt-[2px]'>
                    {`${segment.flight.code} ${segment.flight.number}`}
                  </div>
                </div>
              </div>
            </div>
            <div className='px-4 py-3'>
              <div className='flex justify-between items-center gap-6'>
                <div>
                  <div className='flex gap-2'>
                    <div className='font-medium'>
                      {formatDate(segment.departure.date)}
                    </div>
                    <div className='font-semibold'>
                      {formatTime(segment.departure.time)}
                    </div>
                  </div>
                  <div className='text-xs font-normal text-black/70 mt-[2px] w-32'>
                    {segment.departure.airport.name}
                    <br />
                    {segment.departure.airport.terminal}
                  </div>
                </div>
                <div className='flex flex-col items-center mt-1'>
                  <div className='text-xs font-normal text-black/70'>
                    {formatMinutesToTime(segment.duration)}
                  </div>
                  {/* Line */}
                  <div className='bg-[#ECAE0E] h-1 lg:w-24 xl:w-48 rounded-lg mt-1' />
                  {/*  */}
                  <div className='text-xs font-normal text-black/70 mt-1'>
                    {getFormattedStops(segment.stops)}

                    <span className='ml-2'>
                      {segment.stopsOverAirports.length > 0 ? (
                        <span className='inline-flex gap-1'>
                          (via{' '}
                          {segment.stopsOverAirports.map(
                            (a, index: number) =>
                              `${a.cityCode} ${
                                index === segment.stopsOverAirports.length - 1
                                  ? ''
                                  : ','
                              }   `,
                          )}
                          )
                        </span>
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                </div>
                <div>
                  <div className='flex gap-2'>
                    <div className='font-medium'>
                      {formatDate(segment.arrival.date)}
                    </div>
                    <div className='font-semibold'>
                      {formatTime(segment.arrival.time)}
                    </div>
                  </div>

                  <div className='text-xs font-normal text-black/70 mt-[2px] w-32'>
                    {segment.arrival.airport.name}
                    <br />
                    {segment.arrival.airport.terminal}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {segment.layoverDuration !== 0 && (
            <div className='mx-auto w-60 my-4 bg-gray-100 rounded-full px-8 py-2 text-sm text-center'>
              Layover Time -{' '}
              <span className='font-semibold'>
                {formatMinutesToTime(segment.layoverDuration)}
              </span>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default FlightDetails;
