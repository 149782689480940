import { FlightDirectionEnum } from 'app/enums/flight-direction.enum';
import { TFlightOption } from '../types/FlightsList';
import { useEffect, useState } from 'react';

type TFlightMatcher = Record<FlightDirectionEnum, Array<TFlightOption>> & {
  matcher: Record<FlightDirectionEnum, string>;
};

const useFlightMatcher = (
  props: TFlightMatcher,
): Record<FlightDirectionEnum, Array<TFlightOption>> => {
  const { INBOUND, OUTBOUND, matcher } = props;

  console.log({
    matcher,
  });
  const [matchedOutBoundFlights, setMatchedOutBoundFlights] = useState<
    Array<TFlightOption>
  >([]);
  const [matchedInBoundFlights, setMatchedInBoundFlights] = useState<
    Array<TFlightOption>
  >([]);

  useEffect(() => {
    setMatchedInBoundFlights(filterMatchingFlights(INBOUND, matcher.INBOUND));
    setMatchedOutBoundFlights(
      filterMatchingFlights(OUTBOUND, matcher.OUTBOUND),
    );
  }, [INBOUND, OUTBOUND, matcher]);

  return {
    INBOUND: matchedInBoundFlights,
    OUTBOUND: matchedOutBoundFlights,
  };
};

export default useFlightMatcher;

export const filterMatchingFlights = (
  flightOptions: Array<TFlightOption>,
  matchingFlight: string,
) => {
  // Matching array is empty, will return all flight options without filtering

  console.log({ matchingFlight });

  if (matchingFlight === '') return flightOptions;

  // will hold matching flight options
  const filteredFlightOptions: Array<TFlightOption> = [];

  flightOptions.forEach((flightOption) => {
    const txFlightOption: TFlightOption = {
      ...flightOption,
      priceList: [],
    };

    // checking price list item in matching and push to priceListItems
    flightOption.priceList.forEach((priceListItem) => {
      //   if (matchingFlights.includes(priceListItem.combinationIdentifier)) {
      //     txFlightOption.priceList.push(priceListItem);
      //   }
      if (
        priceListItem.matchingCombinationIdentifiers.includes(matchingFlight)
      ) {
        txFlightOption.priceList.push(priceListItem);
      }
    });

    if (txFlightOption.priceList.length > 0) {
      filteredFlightOptions.push(txFlightOption);
    }
  });

  return filteredFlightOptions;
};
