import http from 'app/config/http';
import { IAirport } from 'app/types';

// Get booking by ID
export const getAirportByCode = async (airportCode: string) => {
  const { data } = await http.post(
    `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/airports/by-code`,
    {
      code: airportCode,
    },
  );
  return data as IAirport;
};
