import { FC } from 'react';
import {
  TFareSupplierReviewConditions,
  TSearchInfo,
} from 'app/modules/ReturnFlights/types/fare-supplier/fare-supplier-review-response.type';
import { Formik } from 'formik';

interface ITravelerDetailsForm {
  conditions: TFareSupplierReviewConditions;
  isInternational: boolean;
  searchInfo: TSearchInfo;
}

const TravelerDetailsForm: FC<ITravelerDetailsForm> = (props) => {
  const { conditions, isInternational, searchInfo } = props;

  return (
    <div className='card mb-4 px-8 py-7'>
      <div className='text-xl font-bold'>Traveler Details</div>
    </div>
  );
};

export default TravelerDetailsForm;
