const Icon = (props: any) => (
  <svg
    width={19}
    height={19}
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle
      cx={9.5}
      cy={9.5}
      r={8.5}
      fill='#fff'
      stroke='#22C55E'
      strokeWidth={2}
    />
    <path
      d='M6.72 13V5.55h2.77c.507 0 .953.093 1.34.28.393.18.7.447.92.8.22.347.33.77.33 1.27 0 .513-.123.95-.37 1.31-.24.36-.567.627-.98.8L12.44 13H10.9L9.07 9.73l.88.52H8.08V13H6.72zm1.36-3.95h1.43c.247 0 .46-.047.64-.14.18-.1.32-.237.42-.41.1-.173.15-.373.15-.6 0-.233-.05-.433-.15-.6-.1-.173-.24-.307-.42-.4-.18-.1-.393-.15-.64-.15H8.08v2.3z'
      fill='#22C55E'
    />
  </svg>
);

export default Icon;
