import moment from 'moment';
import Leg from './components/Leg/Leg';
import { TSearch } from './types/search';
import { RightArrowIcon } from 'app/icons';
import SearchHead from './components/SearchHead';
import { FC, useEffect, useState } from 'react';
import useNProgress from './hooks/useNProgress';
import useReturnLogic from './hooks/useReturnLogic';
import { Filter } from './components/Filter/Filter';
import FilterNav from './components/Filter/FilterNav';
import { TripTypeEnum } from 'app/enums/trip-type.enum';
import { TAppliedFilter } from './types/applied-filter';
import { useCreateFilter } from './hooks/useCreateFilter';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import { filterMatchingFlights } from './hooks/useFlightMatcher';
import { generateDates } from './utils/return-flight-date.utils';
import { FlightDirectionEnum } from 'app/enums/flight-direction.enum';
import LoadingSection from './components/LoadingComponents/LoadingSection';
import { TFlightOption, TFlightOptionSelected } from './types/FlightsList';
import SelectedFlightOverview from './components/SelectedFlightOverview/SelectedFlightOverview';
import { TMasterSearchFareSupplierPriceItem } from './types/fare-supplier-search-response.type';

const convertFlightOptionToSelectedFlightedOption = (
  flight: TFlightOption,
  selectedFlightId: string,
): TFlightOptionSelected => {
  console.log({ flight, selectedFlightId });
  const pricingDetail = flight.priceList.find(
    (priceItem) => priceItem.id === selectedFlightId,
  );

  if (!pricingDetail) return null;

  // remove priceList from Flight,
  //add pricingDetail
  //return return object
  return {
    ...(({ priceList, ...rest }: TFlightOption) => rest)(flight),
    pricingDetail,
  };
};

const filterFlights = (
  flights: Array<TFlightOption>,
  filter: TAppliedFilter,
) => {
  let airlineFilteredFlights: Array<TFlightOption> = [];

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Airline filtering
  if (filter.airlines.length === 0) {
    airlineFilteredFlights = [...flights];
  } else {
    flights.forEach((flight) => {
      if (filter.airlines.includes(flight.Flights[0].code)) {
        airlineFilteredFlights.push(flight);
      }
    });
  }

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Stops filtering
  let stopFilterFlights: Array<TFlightOption> = [];
  if (filter.stops.length === 0) {
    stopFilterFlights = [...airlineFilteredFlights];
  } else {
    airlineFilteredFlights.forEach((flight) => {
      if (filter.stops.includes(flight.stops)) {
        stopFilterFlights.push(flight);
      }
    });
  }

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Time Filter filtering
  let timeFilteredFlight: Array<TFlightOption> = [];

  if (filter.departureTimes.length === 0) {
    timeFilteredFlight = [...stopFilterFlights];
  } else {
    stopFilterFlights.forEach((flight) => {
      if (filter.departureTimes.includes(flight.outBoundDeparture)) {
        timeFilteredFlight.push(flight);
      }
    });
  }

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Price Filter filtering
  const priceRangeFilteredFlight: Array<TFlightOption> = [];

  timeFilteredFlight.forEach((flightOption) => {
    if (flightOption.minPrice > filter.priceLimit) return;

    priceRangeFilteredFlight.push({
      ...flightOption,
      priceList: flightOption.priceList.filter(
        (priceItem) =>
          priceItem.fareDescription.total.totalFare <= filter.priceLimit,
      ),
    });
  });

  // return timeFilteredFlight;
  return priceRangeFilteredFlight;
};

interface IReturnFlights {
  searchQuery: TSearch;
  handleTravelDateChange: (
    direction: FlightDirectionEnum,
    newTravelDate: string,
  ) => void;
}

const ReturnFlights: FC<IReturnFlights> = (props) => {
  const { searchQuery, handleTravelDateChange } = props;

  const [activeFlightDirection, setActiveFlightDirection] =
    useState<FlightDirectionEnum>(FlightDirectionEnum.OUTBOUND);

  const [outBoundFilteredFlight, setOutBoundFilteredFlight] = useState<
    Array<TFlightOption>
  >([]);

  const [inBoundFilteredFlight, setInBoundFilteredFlight] = useState<
    Array<TFlightOption>
  >([]);

  const [appliedFilterOutBound, setAppliedFilterOutBound] =
    useState<TAppliedFilter>({
      airlines: [],
      departureTimes: [],
      stops: [],
      priceLimit: 0,
    });

  const [appliedFilterInBound, setAppliedFilterInBound] =
    useState<TAppliedFilter>({
      airlines: [],
      departureTimes: [],
      stops: [],
      priceLimit: 0,
    });

  const [selectedFlights, setSelectedFlights] = useState<
    Record<FlightDirectionEnum, TFlightOptionSelected | null>
  >({
    INBOUND: null,
    OUTBOUND: null,
  });

  const TabDates = generateDates(
    moment(searchQuery.departureDate, 'YYYYMMDD'),
    moment(searchQuery.returnDate, 'YYYYMMDD'),
    4,
  );

  const handleFlightSelection = (
    flightDirection: FlightDirectionEnum,
    flight: TFlightOption,
    selectedPriceListItem: TMasterSearchFareSupplierPriceItem,
  ) => {
    setSelectedFlights((prev) => ({
      ...prev,
      [flightDirection]: convertFlightOptionToSelectedFlightedOption(
        flight,
        selectedPriceListItem.id,
      ),
    }));
  };

  const { isLoading, inBoundFlight, outBoundFlight } = useReturnLogic({
    searchQuery,
    tripType: TripTypeEnum.ROUND_DOMESTIC_TRIP,
  });

  // useEffect(() => {
  //   if (inBoundFlight.length === 0) return;

  //   const maxPrice = inBoundFlight[inBoundFlight.length - 1].maxPrice;

  //   setAppliedFilterInBound((prev) => ({
  //     ...prev,
  //     priceLimit: maxPrice,
  //   }));
  // }, [inBoundFlight.length]);

  useEffect(() => {
    const maxPrice = getPriceRangeForFilter(outBoundFlight);

    if (maxPrice === null) return;

    setAppliedFilterOutBound((prev) => ({
      ...prev,
      priceLimit: maxPrice,
    }));
  }, [outBoundFlight.length]);

  useEffect(() => {
    const maxPrice = getPriceRangeForFilter(inBoundFlight);

    if (maxPrice === null) return;

    setAppliedFilterInBound((prev) => ({
      ...prev,
      priceLimit: maxPrice,
    }));
  }, [inBoundFlight.length]);

  useNProgress(isLoading);

  const outBoundFilterData = useCreateFilter({ flights: outBoundFlight });
  const inBoundFilterData = useCreateFilter({ flights: inBoundFlight });

  useEffect(() => {
    setOutBoundFilteredFlight(() =>
      filterFlights(outBoundFlight, appliedFilterOutBound),
    );
  }, [appliedFilterOutBound, outBoundFlight]);

  useEffect(() => {
    setInBoundFilteredFlight(() =>
      filterFlights(inBoundFlight, appliedFilterInBound),
    );
  }, [inBoundFlight, appliedFilterInBound]);

  const { INBOUND: finalInBound, OUTBOUND: finalOutBound } = useCombinator({
    INBOUND: inBoundFilteredFlight,
    OUTBOUND: outBoundFilteredFlight,
    selectedFlights,
  });

  return (
    <div className=''>
      <BgOverlay />
      <div className='md:container relative '>
        <div className='grid grid-cols-10 p-2 px-6 gap-9 '>
          <div className='col-span-2 '>
            <div className='card p-6 sticky top-24'>
              <FilterNav
                setActiveFlightDirection={setActiveFlightDirection}
                activeFlightDirection={activeFlightDirection}
                INBOUND={
                  <div className='flex items-center justify-center gap-1'>
                    {searchQuery.to.code} <RightArrowIcon />
                    {searchQuery.from.code}{' '}
                  </div>
                }
                OUTBOUND={
                  <div className='flex items-center justify-center gap-1'>
                    {searchQuery.from.code} <RightArrowIcon />{' '}
                    {searchQuery.to.code}{' '}
                  </div>
                }
              />
              {activeFlightDirection === FlightDirectionEnum.OUTBOUND && (
                <Filter
                  tripFilterOptions={outBoundFilterData}
                  appliedFilter={appliedFilterOutBound}
                  setAppliedFilter={setAppliedFilterOutBound}
                />
              )}

              {activeFlightDirection === FlightDirectionEnum.INBOUND && (
                <Filter
                  tripFilterOptions={inBoundFilterData}
                  appliedFilter={appliedFilterInBound}
                  setAppliedFilter={setAppliedFilterInBound}
                />
              )}
            </div>
          </div>
          <div className='fixed top-0 left-0 z-50 bg-black text-white font-bold'>
            <div>isLoading : {isLoading.toString()}</div>
            <div>out : {outBoundFilteredFlight.length}</div>
            <div>in : {inBoundFilteredFlight.length}</div>
          </div>

          {outBoundFilteredFlight.length > 0 &&
          inBoundFilteredFlight.length > 0 ? (
            <div className=' col-span-8'>
              <div className='grid grid-cols-6 gap-9'>
                <SearchHead searchQuery={searchQuery} />

                <div className='col-span-3'>
                  <Leg
                    from={searchQuery.from}
                    to={searchQuery.to}
                    date={moment(searchQuery.departureDate, 'YYYYMMDD')}
                    flightDirection={FlightDirectionEnum.OUTBOUND}
                    dateList={TabDates.outboundDates}
                    // flightOptions={FINAL_OUTBOUND}
                    // flightOptions={outBoundFilteredFlight}
                    flightOptions={finalOutBound}
                    selectFlight={handleFlightSelection}
                    selectedFlight={selectedFlights.OUTBOUND}
                    isLoading={isLoading && outBoundFilteredFlight.length === 0}
                    handleTravelDateChange={handleTravelDateChange}
                  />
                </div>
                <div className='col-span-3'>
                  <Leg
                    from={searchQuery.to}
                    to={searchQuery.from}
                    date={moment(searchQuery.returnDate, 'YYYYMMDD')}
                    flightDirection={FlightDirectionEnum.INBOUND}
                    dateList={TabDates.inboundDates}
                    // flightOptions={inBoundFilteredFlight}
                    flightOptions={finalInBound}
                    selectFlight={handleFlightSelection}
                    selectedFlight={selectedFlights.INBOUND}
                    isLoading={isLoading && inBoundFilteredFlight.length === 0}
                    handleTravelDateChange={handleTravelDateChange}
                  />
                </div>
              </div>
            </div>
          ) : (
            <LoadingSection searchQuery={searchQuery} TabDates={TabDates} />
          )}
        </div>
      </div>
      <SelectedFlightOverview selectedFlights={selectedFlights} />
    </div>
  );
};

export default ReturnFlights;

type TUseCombinator = Record<FlightDirectionEnum, Array<TFlightOption>> & {
  selectedFlights: Record<FlightDirectionEnum, TFlightOptionSelected | null>;
};

type TReturnUseCombinator = Record<FlightDirectionEnum, Array<TFlightOption>>;

const useCombinator = (props: TUseCombinator): TReturnUseCombinator => {
  const [controlSide, setControlSide] = useState<FlightDirectionEnum | null>(
    null,
  );

  const { INBOUND, OUTBOUND, selectedFlights } = props;

  const [matchingInboundFlights, setMatchingInboundFlights] = useState<
    Array<TFlightOption>
  >([]);
  const [matchingOutBoundFlights, setMatchingOutBoundFlights] = useState<
    Array<TFlightOption>
  >([]);

  useEffect(() => {
    setMatchingInboundFlights(INBOUND);
    setMatchingOutBoundFlights(OUTBOUND);
  }, [INBOUND, OUTBOUND]);

  useEffect(() => {
    if (controlSide === null) {
      if (selectedFlights.INBOUND !== null) {
        setControlSide(FlightDirectionEnum.INBOUND);
        return;
      }
      if (selectedFlights.OUTBOUND !== null) {
        setControlSide(FlightDirectionEnum.OUTBOUND);
        return;
      }
    }
  }, [selectedFlights]);

  useEffect(() => {
    if (controlSide === null) return;

    if (controlSide === FlightDirectionEnum.INBOUND) {
      setMatchingOutBoundFlights(() =>
        filterMatchingFlights(
          OUTBOUND,
          selectedFlights[controlSide].pricingDetail.combinationIdentifier,
        ),
      );
    }

    if (controlSide === FlightDirectionEnum.OUTBOUND) {
      setMatchingInboundFlights(() =>
        filterMatchingFlights(
          INBOUND,
          selectedFlights[controlSide].pricingDetail.combinationIdentifier,
        ),
      );
    }
  }, [controlSide, INBOUND, OUTBOUND, selectedFlights]);
  // check for maniac deps

  return {
    INBOUND: matchingInboundFlights,
    OUTBOUND: matchingOutBoundFlights,
  };
};

const getPriceRangeForFilter = (
  flights: Array<TFlightOption>,
): number | null => {
  if (flights.length === 0) return null;

  let minPrice: number;
  let maxPrice: number;

  flights.forEach((flight, index) => {
    if (index === 0) {
      minPrice = flight.minPrice;
      maxPrice = flight.maxPrice;
    } else {
      if (minPrice > flight.minPrice) {
        minPrice = flight.minPrice;
      }

      if (maxPrice < flight.maxPrice) {
        maxPrice = flight.maxPrice;
      }
    }
  });

  return maxPrice;
};




