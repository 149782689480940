import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { TSearch } from './types/search';
import { FlightDirectionEnum } from 'app/enums/flight-direction.enum';
import useReturnLogic from './hooks/useReturnLogic';
import { TripTypeEnum } from 'app/enums/trip-type.enum';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import SearchHead from './components/SearchHead';
import { TComboFlightOption, TComboOneWay } from './types/FlightsList';
import { FlightSpan } from './components/FlightCard/FlightCard';
import { RefundableEnum } from 'app/enums';

import { NonRefundIcon, RefundableIcon, RefundPartialIcon } from 'app/icons';
import Tooltip from 'app/components/Tooltip/Tooltip';
import { formatCurrency } from 'app/utils/currency-utils';
import LottieLoader from 'app/components/LottieLoader/LottieLoader';
import searchLoaderLottie from 'assets/lotties/search-loader.json';
import { TMasterSearchFareSupplierPriceItem } from './types/fare-supplier-search-response.type';
import { useCreateComboFlightFilter } from './hooks/filters/useCreateComboFlightFilter';
import ComboFlightFilter from './components/ComboFlightFilter/ComboFlightFilter';
import { TComboAppliedFilter } from './types/combo-applied-filter';
import { filterComboFlightsByDeparturePeriod } from './utils/filter/combo/filter-combo-flights-by-departure-period';
import { filterComboFlightsByPrice } from './utils/filter/combo/filter-combo-flights-by-price';
import { filterComboFlightsByStops } from './utils/filter/combo/filter-combo-flights-by-stops';
import { filterComboFlightsByAirline } from './utils/filter/combo/filter-combo-flights-by-airline';
import { AirlineLogo } from '../shared/Components';

interface IInternationalReturn {
  searchQuery: TSearch;
  handleTravelDateChange: (
    direction: FlightDirectionEnum,
    newTravelDate: string,
  ) => void;
}

const InternationalReturn: FC<IInternationalReturn> = (props) => {
  const { searchQuery, handleTravelDateChange } = props;
  const [filteredComboFlights, setFilteredComboFlights] = useState<
    Array<TComboFlightOption>
  >([]);

  const [appliedComboFlightFilter, setAppliedComboFlightFilter] =
    useState<TComboAppliedFilter>({
      airlines: {
        OUTBOUND: [],
        INBOUND: [],
      },
      priceLimit: 0,
      stops: {
        INBOUND: [],
        OUTBOUND: [],
      },
      departureTimes: {
        INBOUND: [],
        OUTBOUND: [],
      },
    });

  const { isLoading, comboFlight } = useReturnLogic({
    searchQuery,
    tripType: TripTypeEnum.ROUND_INTERNATIONAL_TRIP,
  });

  const comboFilterData = useCreateComboFlightFilter({
    flights: comboFlight,
  });

  useEffect(() => {
    if (
      comboFilterData.PriceRange.max === 0 ||
      appliedComboFlightFilter.priceLimit !== 0
    )
      return;

    setAppliedComboFlightFilter((prev) => ({
      ...prev,
      priceLimit: comboFilterData.PriceRange.max,
    }));
  }, [comboFilterData.PriceRange.max]);

  useEffect(() => {
    setFilteredComboFlights(() =>
      filterFlights(comboFlight, appliedComboFlightFilter),
    );
  }, [appliedComboFlightFilter, comboFlight]);

  console.log({
    isLoading,
    comboFlight,
    comboFilterData,
    appliedComboFlightFilter,
  });

  return (
    <div className=''>
      <BgOverlay />
      <div className='md:container relative '>
        <div className='grid grid-cols-10 p-2 px-6 gap-9 '>
          <div className='col-span-2 '>
            <ComboFlightFilter
              comboFlightFilterOptions={comboFilterData}
              appliedComboFlightFilter={appliedComboFlightFilter}
              setAppliedComboFlightFilter={setAppliedComboFlightFilter}
            />
          </div>
          <div className='fixed top-0 left-0 z-50 bg-black text-white font-bold'>
            <div>isLoading : {isLoading.toString()}</div>
            {/* <div>out : {outBoundFilteredFlight.length}</div>
            <div>in : {inBoundFilteredFlight.length}</div> */}
          </div>

          <div className=' col-span-8'>
            <div className='grid grid-cols-6 gap-9'>
              <SearchHead searchQuery={searchQuery} />
              <div className='col-span-6'>
                {isLoading && (
                  <div className='card p-7 pt-4 h-96'>
                    <div className='text-center'>
                      <p className='mt-12 text-lg font-semibold'>
                        Hold on, we are searching for the best fares
                      </p>
                      <div className='w-1/2 mx-auto'>
                        <LottieLoader animationData={searchLoaderLottie} />
                      </div>
                    </div>
                  </div>
                )}
                {/* <ComboFlightList flightOptions={comboFlight} /> */}
                <ComboFlightList flightOptions={filteredComboFlights} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternationalReturn;

interface IComboFlightList {
  flightOptions: Array<TComboFlightOption>;
}

const ComboFlightList: FC<IComboFlightList> = (props) => {
  const { flightOptions } = props;
  return (
    <>
      {flightOptions.map((option, index) => (
        <ComboFlightCard flightOption={option} key={option.priceList[0].id} />
      ))}
    </>
  );
};

interface IComboFlightCard {
  flightOption: TComboFlightOption;
}

const ComboFlightCard: FC<IComboFlightCard> = (props) => {
  const { flightOption } = props;

  const [selectedPriceId, setSelectedPriceId] = useState<string>(null);

  useEffect(() => {
    setSelectedPriceId(flightOption.priceList[0].id);
  }, []);

  return (
    <div className='card mb-4 p-7'>
      <div className='grid grid-cols-10 '>
        <div className='col-span-3'>
          <OneSideFlightRender oneWayFlight={flightOption.OUTBOUND} />
        </div>
        <div className='col-span-3'>
          <OneSideFlightRender oneWayFlight={flightOption.INBOUND} />
        </div>

        <div className='col-span-4'>
          <ComboPricingList
            priceList={flightOption.priceList}
            selectedPriceId={selectedPriceId}
            setSelectedPriceId={setSelectedPriceId}
          />
        </div>
      </div>
      <div className='flex items-center justify-end'>
        <button className='btn btn-primary'>Book</button>
      </div>
    </div>
  );
};

interface IOneSideFlightRender {
  oneWayFlight: TComboOneWay;
}

const OneSideFlightRender: FC<IOneSideFlightRender> = (props) => {
  const { oneWayFlight } = props;

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex gap-3 '>
        <AirlineLogo
          src={oneWayFlight.flights[0].logo}
          alt={`${oneWayFlight.flights[0].name} logo`}
        />
        <div>
          <div className='text-base leading-5 font-semibold'>
            {/* {getFlightNames()} */}
            {oneWayFlight.flights[0].name}
          </div>
          <div className='rounded-full bg-[#C8C8C8] h-1 w-1 '></div>
          <div className='text-xs leading-normal font-medium text-black/70'>
            {oneWayFlight.flights[0].number}
            {/* {getFlightNumbers()} */}
            {/* {RenderFlightNumber(flightOption.Flights)} */}
          </div>
        </div>
      </div>

      <FlightSpan
        from={oneWayFlight.from}
        to={oneWayFlight.to}
        segments={oneWayFlight.segments}
        duration={oneWayFlight.duration}
      />
    </div>
  );
};

interface IComboPricingList {
  priceList: Array<TMasterSearchFareSupplierPriceItem>;
  selectedPriceId: string;
  setSelectedPriceId: Dispatch<SetStateAction<string>>;
}

const ComboPricingList: FC<IComboPricingList> = (props) => {
  const { priceList, selectedPriceId, setSelectedPriceId } = props;

  return (
    <>
      {priceList.map((priceItem) => (
        <div className='grid grid-cols-8 mb-4' key={priceItem.id}>
          <div className='col-span-3 flex items-center gap-2'>
            <input
              id={priceItem.id}
              type='radio'
              className='form-radio'
              checked={selectedPriceId === priceItem.id}
              onChange={() => setSelectedPriceId(priceItem.id)}

              // onChange={() => setSelectedPriceItemIndex(i)}
              // onClick={() => {
              //   createEvent({
              //     priceListIndex: index,
              //     selectedPriceItemIndex: i,
              //     type: 'SELECTED',
              //   });
              // }}
            />
            {/* <div className="line-through text-sm text-black/70">₹5,800</div> */}
            <label
              className=' leading-6 font-bold flex gap-2 items-center'
              htmlFor={priceItem.id}
            >
              <span className='whitespace-nowrap'>
                <div>
                  {formatCurrency(priceItem.fareDescription.total.totalFare)}
                </div>{' '}
                {/* {formatCurrency(price.totalFare)} */}
                {/* <PriceWithHiddenSupplier
                  totalFare={
                    price.totalFare + subAgencyAdditionalMarkup
                    // subAgencyAdditionalMarkup.total
                  }
                  fareSupplier={price.fareSupplier}
                /> */}
              </span>
              {priceItem.isOwn && (
                <div className='bg-green-500 h-2 w-2 rounded-full' />
              )}
            </label>
          </div>
          <div className='col-span-2 flex items-center text-sm leading-[18px] font-bold text-green-600 relative   '>
            {formatCurrency(priceItem.fareDescription.total.commissionNet)}
          </div>
          <div className='col-span-3 flex items-center gap-2'>
            <RefundTypeLogoLabel
              refundType={priceItem.fareDescription.adult.refundableType}
            />
            <FareIdentifierLabel>
              {priceItem.fareIdentifier}
            </FareIdentifierLabel>
          </div>

          {/* <div>{priceItem.fareDescription.total.total}</div>
          <div>{priceItem.fareDescription.total.net}</div> */}
        </div>
      ))}
    </>
  );
};

// ----------------------------------------------------------------- Fare Identifier Label -----------------------------------
interface IFareIdentifierLabel {
  children: string;
}

const FareIdentifierLabel: FC<IFareIdentifierLabel> = (props) => {
  const { children } = props;

  return (
    <div className='flex items-center gap-2 mt-1 text-[0.65rem] font-semibold text-gray-500'>
      <div className='px-2 py-1 text-black bg-[#ECAE0E] rounded-md'>
        {children}
      </div>
    </div>
  );
};

// ----------------------------------------------------------------- Refund Label -----------------------------------

interface IRefundTypeLogoLabel {
  refundType: RefundableEnum;
}

const RefundTypeLogoLabel: FC<IRefundTypeLogoLabel> = (props) => {
  //  NonRefundIcon ,RefundPartialIcon , RefundableIcon

  const { refundType } = props;

  let icon: ReactNode;

  switch (refundType) {
    case RefundableEnum.NON_REFUNDABLE:
      icon = <NonRefundIcon />;
      break;
    case RefundableEnum.REFUNDABLE:
      icon = <RefundableIcon />;
      break;
    case RefundableEnum.PARTIAL_REFUNDABLE:
      icon = <RefundPartialIcon />;
      break;
    default:
  }

  return (
    <Tooltip
      content={
        <div className=''>
          <div className='px-2 pr-4 py-1 text-xs font-bold'>{refundType}</div>
        </div>
      }
      placement='left'
    >
      {icon}
    </Tooltip>
  );
};

// -------------------------------------------------------------- Filter function ----------------------------------

const filterFlights = (
  comboFlight: Array<TComboFlightOption>,
  appliedComboFlightFilter: TComboAppliedFilter,
): Array<TComboFlightOption> => {
  // const steps = [filterComboFlightsByDeparturePeriod];

  const step1 = filterComboFlightsByDeparturePeriod(
    comboFlight,
    appliedComboFlightFilter,
  );
  const step2 = filterComboFlightsByPrice(step1, appliedComboFlightFilter);
  const step3 = filterComboFlightsByStops(step2, appliedComboFlightFilter);
  const step4 = filterComboFlightsByAirline(step3, appliedComboFlightFilter);

  return step4;
};

// type z = (
//   comboFlights: Array<TComboFlightOption>,
//   filter: TComboAppliedFilter,
// ) => Array<TComboFlightOption>;

// export const pipeline = (filterSteps: Array<z>) => {
//   return () => filterSteps.reduce((value, fn) => fn(value), input);
// };
