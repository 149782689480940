import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import { splitPriceItemKeys } from '../shared/utils/common.utils';
import ProcessHeading from 'app/components/ProcessHeading/ProcessHeading';
import { getReviewFlightDetails } from 'app/api/services/review-flight.service';
import { TMasterFareSupplierReviewResponse } from '../ReturnFlights/types/master/master-review-response.type';
import TravelerDetailsForm from './components/TripDetailCard/components/TravelerDetailsForm/TravelerDetailsForm';

const ReviewSearch = () => {
  const { keys } = useParams();

  const flightReviewQuery = useQuery<TMasterFareSupplierReviewResponse>(
    [keys],
    () => getReviewFlightDetails(splitPriceItemKeys(keys)),
  );
  console.log({ data: flightReviewQuery.data });

  if (flightReviewQuery.isLoading) return <div>Loading...</div>;

  const reviewData = flightReviewQuery.data;

  return (
    <div>
      <BgOverlay />
      <div className='container min-h-full'>
        <ProcessHeading title='Complete your booking' activeIndex={1} />
        <div className='grid grid-cols-3 gap-9'>
          <div className='col-span-3 lg:col-span-2'>
            <TravelerDetailsForm
              conditions={reviewData.conditions}
              searchInfo={reviewData.searchInfo}
              isInternational={reviewData.isInternational}
            />
            {/* {flightReviewQuery.data.tripInfos.map((trip) => (
              <TripDetailsCard trip={trip} />
            ))} */}
            {/* <TripDetailsCard trip={trip} /> */}
          </div>
          <div className='col-span-3 lg:col-span-1'>
            <div className='card'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewSearch;
