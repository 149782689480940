import { Moment } from 'moment';
import { IAirport } from 'app/types';
import { IDateTab } from '../../types/date-tab';
import FlightCard from '../FlightCard/FlightCard';
import CloseDates from '../CloseDates/CloseDates';
import { TFlightOption, TFlightOptionSelected } from '../../types/FlightsList';
import { FC, useRef } from 'react';
import { FlightDirectionEnum } from 'app/enums/flight-direction.enum';
import LottieLoader from 'app/components/LottieLoader/LottieLoader';
import searchLoaderLottie from 'assets/lotties/search-loader.json';
import { TMasterSearchFareSupplierPriceItem } from '../../types/fare-supplier-search-response.type';

// const filterAnd

interface ILeg {
  flightDirection: FlightDirectionEnum;
  from: IAirport;
  to: IAirport;
  date: Moment;
  dateList: Array<IDateTab>;
  flightOptions: Array<TFlightOption>;
  selectFlight: (
    flightDirection: FlightDirectionEnum,
    flight: TFlightOption,
    selectedPriceListItem: TMasterSearchFareSupplierPriceItem,
  ) => void;

  selectedFlight: TFlightOptionSelected | null;
  isLoading: boolean;
  handleTravelDateChange: (
    direction: FlightDirectionEnum,
    newTravelDate: string,
  ) => void;
}

const Leg: FC<ILeg> = (props) => {
  const {
    from,
    to,
    date,
    dateList,
    flightOptions,
    selectFlight,
    flightDirection,
    selectedFlight,
    isLoading,
    handleTravelDateChange,
  } = props;
  const elementRef = useRef(null);

  // const [height, setHeight] = useState(window.innerHeight);
  // const [elementTop, setElementTop] = useState(0);
  // useEffect(() => {
  //   // Function to update height and element's position
  //   const updateValues = () => {
  //     setHeight(window.innerHeight); // Set the window height

  //     if (elementRef.current) {
  //       const rect = elementRef.current.getBoundingClientRect();
  //       setElementTop(rect.top + window.scrollY); // Set element's position from top
  //     }
  //   };

  //   // Update values on initial render
  //   updateValues();

  //   // Debounce the resize handler for better performance
  //   const debouncedUpdate = debounce(updateValues, 0);

  //   // Add resize event listener
  //   window.addEventListener('resize', debouncedUpdate);

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     window.removeEventListener('resize', debouncedUpdate);
  //   };
  // }, []);

  const handleSelect = (
    flight: TFlightOption,
    selectedPriceListItem: TMasterSearchFareSupplierPriceItem,
  ) => {
    selectFlight(flightDirection, flight, selectedPriceListItem);
  };

  return (
    <div>
      <div className='card px-4 py-5 '>
        <div className='flex justify-between items-center'>
          <div className='text-base leading-5 font-semibold'>
            {from.city} to {to.city}
          </div>
          <div className='text-xs font-normal'>
            {date.format('DD MMMM, YYYY')}
          </div>
        </div>
      </div>

      <div className='my-9'>
        <CloseDates
          tabDates={dateList}
          handleDateChange={(newTravelDate: string) =>
            handleTravelDateChange(flightDirection, newTravelDate)
          }
        />
      </div>

      <div ref={elementRef} className='flex flex-col gap-2 mb-36'>
        {isLoading ? (
          <div className='card p-7 h-80'>
            <div className='text-center'>
              <p className='mt-12 text-lg font-semibold'>
                Hold on, we are searching for the best fares
              </p>
              <div className='w-1/2 mx-auto'>
                <LottieLoader animationData={searchLoaderLottie} />
              </div>
            </div>
          </div>
        ) : (
          <>
            {flightOptions.map((flightOption, index) => (
              <FlightCard
                flightOption={flightOption}
                key={generateKey(flightOption, index)}
                handleSelect={handleSelect}
                selectedFlight={selectedFlight?.pricingDetail?.id || ''}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const generateKey = (flightOption: TFlightOption, index: number) => {
  return `${flightOption.priceList[0].fareIdentifier}-${flightOption.priceList[0].combinationIdentifier}-${flightOption.priceList[0].fareIdentifier}-${index}`;
};

export default Leg;
