import React, { FC, useState } from 'react';
import FlightTabNav from './FlightTabNav';
import { FlightDirectionEnum } from 'app/enums/flight-direction.enum';
import { TFlightOptionSelected } from 'app/modules/ReturnFlights/types/FlightsList';
import SelectedFlightDetail from './SelectedFlightDetail';

type TSelectedFlightsDetail = Record<
  FlightDirectionEnum,
  TFlightOptionSelected
> & {};

const SelectedFlightsDetail: FC<TSelectedFlightsDetail> = (props) => {
  const { INBOUND, OUTBOUND } = props;

  const [activeFlightDirection, setActiveFlightDirection] =
    useState<FlightDirectionEnum>(FlightDirectionEnum.OUTBOUND);

  return (
    <div>
      <FlightTabNav
        activeFlightDirection={activeFlightDirection}
        setActiveFlightDirection={setActiveFlightDirection}
        OUTBOUND={`${OUTBOUND.from.airport.code} - ${OUTBOUND.to.airport.code}`}
        INBOUND={`${INBOUND.from.airport.code} - ${INBOUND.to.airport.code}`}
      />
      <div className=''>
        {FlightDirectionEnum.OUTBOUND === activeFlightDirection && (
          <SelectedFlightDetail flight={OUTBOUND} />
        )}

        {FlightDirectionEnum.INBOUND === activeFlightDirection && (
          <SelectedFlightDetail flight={INBOUND} />
        )}
      </div>
    </div>
  );
};

export default SelectedFlightsDetail;
