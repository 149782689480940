import { Moment } from 'moment';
import { FC } from 'react';
import { FlightDirectionEnum } from 'app/enums/flight-direction.enum';
import { IAirport } from 'app/types';
import { IDateTab } from '../../types/date-tab';
import CloseDates from '../CloseDates/CloseDates';

interface ILegInitialLoading {
  flightDirection: FlightDirectionEnum;
  from: IAirport;
  to: IAirport;
  date: Moment;
  dateList: Array<IDateTab>;
}

const LegInitialLoading: FC<ILegInitialLoading> = (props) => {
  const {
    date,
    dateList,
    flightDirection,
    from,
    to,
    // handleTravelDateChange,
  } = props;

  return (
    <>
      {' '}
      <div className='card px-4 py-5 '>
        <div className='flex justify-between items-center'>
          <div className='text-base leading-5 font-semibold'>
            {from.city} to {to.city}
          </div>
          <div className='text-xs font-normal'>
            {date.format('DD MMMM, YYYY')}
          </div>
        </div>
      </div>
      <div className='my-9'>
        <CloseDates
          tabDates={dateList}
          handleDateChange={(newTravelDate: string) => {}}
        />
      </div>
    </>
  );
};

export default LegInitialLoading;
