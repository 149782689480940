import MainLayout from 'app/layouts/MainLayout/MainLayout';
import SeriesInventoryLayout from 'app/layouts/SeriesInventoryLayout/SeriesInventoryLayout';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AuthGuard from 'app/hocs/AuthGuard/AuthGuard';
import ErrorBoundary from 'app/hocs/ErrorBoundary/ErrorBoundary';
import Account from 'app/pages/Account/Account';
import BookingDetail from 'app/pages/BookingDetail/BookingDetail';
import BookingInvoice from 'app/pages/BookingInvoice/BookingInvoice';
import Bookings from 'app/pages/Bookings/Bookings';
import Confirmation from 'app/pages/Confirmation/Confirmation';
import Flights from 'app/pages/Flights/Flights';
import Home from 'app/pages/Home/Home';
import Payment from 'app/pages/Payment/Payment';
import Profile from 'app/pages/Profile/Profile';
import Login from 'app/pages/Login/Login';
import SubAgencyUserAdd from 'app/pages/SubAgencyUserAdd/SubAgencyUserAdd';
import SubAgencyUserEdit from 'app/pages/SubAgencyUserEdit/SubAgencyUserEdit';
import SubAgencyUsers from 'app/pages/SubAgencyUsers/SubAgencyUsers';
import AuthLayout from 'app/layouts/AuthLayout/AuthLayout';
import BookingTicket from 'app/pages/BookingTicket/BookingTicket';
import AgencyRegistration from 'app/pages/AgencyRegistration/AgencyRegistration';
import PageNotFound from 'app/pages/PageNotFound/PageNotFound';

import Dashboard from 'app/pages/SeriesInventory/Pages/Dashboard/Dashboard';
import SeriesInventoryAdd from 'app/pages/SeriesInventory/Pages/SeriesInventoryAdd/SeriesInventoryAdd';
import SeriesInventoryList from 'app/pages/SeriesInventory/Pages/SeriesInventoryList/SeriesInventoryList';
import SeriesInventoryBookingList from 'app/pages/SeriesInventory/Pages/SeriesInventoryBookingList/SeriesInventoryBookingList';

import BookingList from 'app/pages/SeriesInventory/Pages/BookingList/BookingList';
import FareBookingDetail from 'app/pages/SeriesInventory/Pages/BookingDetail/BookingDetail';
import SeriesInventoryEdit from 'app/pages/SeriesInventory/Pages/SeriesInventoryEdit/SeriesInventoryEdit';
import ReviseMarkup from 'app/pages/SeriesInventory/Pages/ReviseMarkup/ReviseMarkup';
// import BookingTicketSeries from 'app/pages/SeriesInventory/Pages/BookingTicket/BookingTicket'; removed component
import BookingInvoiceSeries from 'app/pages/SeriesInventory/Pages/BookingInvoice/BookingInvoice';
import Accounting from 'app/pages/SeriesInventory/Pages/Accounting/Accounting';
import PluginGuard from 'app/hocs/PluginGuard/PluginGuard';
import ForgetPassword from 'app/pages/ForgetPassword/ForgetPassword';
import ResetPassword from 'app/pages/ResetPassword/ResetPassword';
import TourQueryList from 'app/pages/TourQuery/TourQueryList/TourQueryList';
import TourQueryAdd from 'app/pages/TourQuery/TourQueryAdd/TourQueryAdd';
import TourQueryView from 'app/pages/TourQuery/TourQueryView/TourQueryView';
import StaticPage from 'app/pages/StaticPage/StaticPage';
import MobileSearch from 'app/pages/MobileSearch/MobileSearch';
import PassengerBooking from 'app/modules/booking/pages/PassengerBooking/PassengerBooking';

import AddPassenger from 'app/modules/SavedPassenger/AddPassenger/AddPassenger';
import EditPassenger from 'app/modules/SavedPassenger/EditPassenger/EditPassenger';
import SavedPassengerList from 'app/modules/SavedPassenger/SavedPassengerList';
import PaymentRef from 'app/pages/PaymentRef/PaymentRef';
import Redirection from 'app/pages/Redirection/Redirection';
import ReturnFlights from 'app/modules/ReturnFlights/ReturnFlights';
import RoundTripSearch from 'app/modules/ReturnFlights/RoundTripSearch';
import ReviewSearch from 'app/modules/ReviewSearch/Page';

const AppRouter = () => {
  // const pluginRoutes: any = [];

  // const navItemsQuery = useQuery(['plugin'], async () => {
  //     const { data } = await http.get(`${process.env.REACT_APP_API_URL}/api/v1/sub-agent/plugins/nav-items`);

  //     return data;
  // });

  // if (navItemsQuery.isLoading) return <PageLoader />;

  // const plugins = navItemsQuery.data;

  // const checkPluginRoute = (plugIn: string, activatedPlugins: any) => {
  //     if (activatedPlugins.find((val: any) => val._id === plugIn)) {
  //         return true;
  //     }
  //     return false;
  // };

  // if (checkPluginRoute('my-series', plugins)) {
  // }

  const routes = createBrowserRouter([
    // Unauthentiated routes
    { path: 'login', element: <Login />, errorElement: <ErrorBoundary /> },
    {
      path: 'register',
      element: <AgencyRegistration />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: 'password/forget',
      element: <ForgetPassword />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: 'password/reset/:token',
      element: <ResetPassword />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: 'static/:route',
      element: <StaticPage />,
      errorElement: <ErrorBoundary />,
    },

    // Authentiated routes

    {
      path: '/redirecting',
      element: <AuthGuard page={<Redirection />} />,
      errorElement: <ErrorBoundary />,
    },

    {
      path: '/',
      element: <AuthGuard page={<AuthLayout />} />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: '',
          element: <MainLayout />,
          children: [
            { path: '', element: <Home /> },
            { path: 'flights', element: <Flights /> },

            { path: 'flights-return', element: <RoundTripSearch /> },
            {
              path: '/v2/flights-return/:keys',
              element: <ReviewSearch />,
            },

            // { path: 'flights-return', element: <ReturnFlights /> },
            { path: 'flights/:fareId', element: <PassengerBooking /> },
            // { path: 'payment/:id', element: <Payment /> },
            { path: 'payment/:id', element: <PaymentRef /> },
            // { path: 'paymentref/:id', element: <PaymentRef /> },
            { path: 'confirmation/:id', element: <Confirmation /> },
            { path: 'bookings', element: <Bookings /> },
            { path: 'bookings/:bookingId', element: <BookingDetail /> },
            { path: 'my-agency/account', element: <Account /> },
            { path: 'my-agency/Passengers', element: <SavedPassengerList /> },
            { path: 'my-agency/Passengers/add', element: <AddPassenger /> },
            { path: 'my-agency/Passengers/:id', element: <EditPassenger /> },
            { path: 'users', element: <SubAgencyUsers /> },
            { path: 'users/add', element: <SubAgencyUserAdd /> },
            { path: 'users/:userId/edit', element: <SubAgencyUserEdit /> },
            { path: 'profile', element: <Profile /> },

            {
              path: '/my-series',
              element: (
                <PluginGuard
                  pluginId='my-series'
                  page={<SeriesInventoryLayout />}
                />
              ),
              children: [
                {
                  index: true,
                  element: <Dashboard />,
                },
                { path: 'series-inventory', element: <SeriesInventoryList /> },
                {
                  path: 'series-inventory/add',
                  element: <SeriesInventoryAdd />,
                },
                {
                  path: 'series-inventory/:inventoryId/edit',
                  element: <SeriesInventoryEdit />,
                },
                {
                  path: 'series-inventory/:inventoryId/bookings',
                  element: <SeriesInventoryBookingList />,
                },
                {
                  path: 'series-inventory/:inventoryId/revise-markup',
                  element: <ReviseMarkup />,
                },
                { path: 'bookings', element: <BookingList /> },
                { path: 'bookings/:bookingId', element: <FareBookingDetail /> }, // static data on this route
                // { path: 'bookings/:bookingId/ticket', element: <BookingTicketSeries /> }, // static data on this route

                { path: 'account', element: <Accounting /> },
              ],
            },
            { path: 'tour-query', element: <TourQueryList /> },
            { path: 'tour-query/add', element: <TourQueryAdd /> },
            { path: 'tour-query/:id', element: <TourQueryView /> },
            { path: 'mobile-search', element: <MobileSearch /> },
            // { path: 'avenue-payment', element: <CcAvenuePayment /> },
          ],
        },
        // {
        //     removed components
        //     path: '/my-series/bookings/:bookingId/ticket',
        //     element: <AuthGuard page={<BookingTicketSeries />} />,
        //     errorElement: <ErrorBoundary />,
        // },
        {
          path: '/my-series/bookings/:bookingId/invoice',
          element: <AuthGuard page={<BookingInvoiceSeries />} />,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/bookings/:bookingId/ticket',
          element: <AuthGuard page={<BookingTicket />} />,
          errorElement: <ErrorBoundary />,
        },
        {
          path: '/bookings/:bookingId/invoice',
          element: <AuthGuard page={<BookingInvoice />} />,
          errorElement: <ErrorBoundary />,
        },
      ],
    },

    ////////////////
    // {
    //     path: '/',
    //     element: <AuthGuard page={<AuthLayout />} />,
    //     errorElement: <ErrorBoundary />,
    //     children: [
    //         {
    //             path: 'mobile-search',
    //             element: <MobileSearch />,
    //             // children: [{ path: 'mobile-search', element: <MobileSearch /> }],
    //         },
    //     ],
    // },
    ////////////////

    { path: '*', element: <PageNotFound /> },
  ]);

  return <RouterProvider router={routes} />;
};

export default AppRouter;
