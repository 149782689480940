import {
  TMasterFareSupplierSearchResult,
  TMasterSearchFareSupplierPriceItem,
} from '../types/fare-supplier-search-response.type';
import { TComboFlightOption } from '../types/FlightsList';
import { TFareSupplierSegment } from '../types/segment.type';
import { getStopsEnum, getTimeOfDay } from './useReturnLogic';
import { produce } from 'immer';

const OutBoundTripSegmentIdentifier = 1;
const InBoundTripSegmentIdentifier = 2;

const getFilteredSegments = (segments: Array<TFareSupplierSegment>) => {
  const unsortedOutboundSegments: Array<TFareSupplierSegment> = [];
  const unsortedInboundSegments: Array<TFareSupplierSegment> = [];

  segments.forEach((segment) => {
    if (segment.trip === OutBoundTripSegmentIdentifier) {
      unsortedOutboundSegments.push(segment);
    } else if (segment.trip === InBoundTripSegmentIdentifier) {
      unsortedInboundSegments.push(segment);
    } else {
      throw Error(`Unknown Trip encountered ${segment.trip}`);
    }
  });

  return {
    outBoundSegments: unsortedOutboundSegments.sort(
      (a, b) => a.segmentNumber - b.segmentNumber,
    ),
    inBoundSegments: unsortedInboundSegments.sort(
      (a, b) => a.segmentNumber - b.segmentNumber,
    ),
  };
};

const getFrom = (segments: Array<TFareSupplierSegment>) => {
  return segments[0].departure;
};

const getTo = (segments: Array<TFareSupplierSegment>) => {
  return segments[segments.length - 1].arrival;
};

const getFlights = (segments: Array<TFareSupplierSegment>) => {
  return segments.map((segment) => segment.flight);
};

export const sortPriceList = (
  priceList: Array<TMasterSearchFareSupplierPriceItem>,
): Array<TMasterSearchFareSupplierPriceItem> => {
  let clonedPriceList = [...priceList];

  clonedPriceList.sort(
    (item, successorItem) =>
      +item.fareDescription.total.totalBaseFare -
      +successorItem.fareDescription.total.totalFare,
  );

  return clonedPriceList;
};

export const filterSegmentsAndCreateOptimizedCombo = (
  combo: Array<TMasterFareSupplierSearchResult>,
): Array<TComboFlightOption> => {
  const getMinMaxPrice = (
    priceList: Array<TMasterSearchFareSupplierPriceItem>,
  ): { minPrice: number; maxPrice: number } => {
    let minPrice = 0;
    let maxPrice = 0;

    priceList.forEach((price, index) => {
      if (index === 0) {
        minPrice = price.fareDescription.total.totalFare;
        maxPrice = price.fareDescription.total.totalFare;
      }

      if (minPrice > price.fareDescription.total.totalFare) {
        minPrice = price.fareDescription.total.totalFare;
      }

      if (maxPrice < price.fareDescription.total.totalFare) {
        maxPrice = price.fareDescription.total.totalFare;
      }
    });

    return {
      maxPrice,
      minPrice,
    };
  };


  const getTripDuration = (
    comboOption: TMasterFareSupplierSearchResult,
    tripIndex: number,
  ) => {
    const txDuration = comboOption.tripsInfo.find(
      (tripObject) => tripObject.trip === tripIndex,
    );

    if (!txDuration) return 0;

    return txDuration.duration;
  };

  return combo
    .map((comboOption) => {
      const { inBoundSegments, outBoundSegments } = getFilteredSegments(
        comboOption.segments,
      );

      return {
        priceList: sortPriceList(comboOption.priceList),
        OUTBOUND: {
          segments: outBoundSegments,
          from: getFrom(outBoundSegments),
          to: getTo(outBoundSegments),
          flights: getFlights(outBoundSegments),
          stops: getStopsEnum(outBoundSegments.length),
          duration: getTripDuration(comboOption, 1),
          departureTiming: (() => {
            let x = getTimeOfDay(outBoundSegments[0].departure.time);
            return x;
          })(),
        },
        INBOUND: {
          segments: inBoundSegments,
          from: getFrom(inBoundSegments),
          to: getTo(inBoundSegments),
          flights: getFlights(inBoundSegments),
          stops: getStopsEnum(inBoundSegments.length),
          duration: getTripDuration(comboOption, 2),
          departureTiming: (() => {
            let x = getTimeOfDay(inBoundSegments[0].departure.time);
            return x;
          })(),
        },
        ...getMinMaxPrice(comboOption.priceList),
      };
    })
    .sort((item, successorItem) => +item.minPrice - +successorItem.minPrice);
};
