import { FlightDirectionEnum } from 'app/enums/flight-direction.enum';
import React, { Dispatch, FC, ReactNode, SetStateAction } from 'react';

type TFilterNav = Record<FlightDirectionEnum, ReactNode> & {
  activeFlightDirection: FlightDirectionEnum;
  setActiveFlightDirection: Dispatch<SetStateAction<FlightDirectionEnum>>;
};

const FilterNav: FC<TFilterNav> = (props) => {
  const { activeFlightDirection, setActiveFlightDirection, OUTBOUND, INBOUND } =
    props;

  return (
    <div className='flex mb-4 gap-1'>
      <button
        className={`  border-2  w-full  ${
          activeFlightDirection === FlightDirectionEnum.OUTBOUND
            ? 'font-bold cursor-default text-primary '
            : ''
        }`}
        onClick={() => setActiveFlightDirection(FlightDirectionEnum.OUTBOUND)}
      >
        {OUTBOUND}
      </button>
      <button
        className={`  border-2  w-full ${
          activeFlightDirection === FlightDirectionEnum.INBOUND
            ? 'font-bold cursor-default text-primary '
            : ''
        }`}
        onClick={() => setActiveFlightDirection(FlightDirectionEnum.INBOUND)}
      >
        {INBOUND}
      </button>
    </div>
  );
};

export default FilterNav;
