import { FC } from 'react';
import { SwapIcon } from 'app/icons';
import { TSearch } from '../../types/search';
import { DatePicker } from 'app/library/datepicker';
import { generateNumberArray } from 'app/utils/list-utils';
import { CabinClassEnum } from 'app/enums/cabin-class.enum';
import { TripTypeSearchFormEnum } from 'app/enums/trip-type.enum';
import { ErrorMessage, Field, Formik, FormikProps, Form } from 'formik';
import { Dropdown, DropdownList } from 'app/components/Dropdown/Dropdown';
import { getCabinClassLabel, getTripTypeLabel } from 'app/utils/flight-utils';
import AirportSearchField from 'app/components/AirportSearchField/AirportSearchField';
import moment from 'moment';
import { SYSTEM_DATE_FORMAT } from 'app/utils/date-utils';
import * as Yup from 'yup';


const SearchFormSchema = Yup.object({
  from: Yup.object().nullable().required('This field is required'),
  to: Yup.object()
    .nullable()
    .required('This field is required')
    .test('not-same', 'Please select different to and from', function (to) {
      const { from } = this.parent;
      if (!to || !from) return false;
      return from.code !== to.code;
    }),
  adult: Yup.number()
    .min(1, 'At least one adult is required')
    .max(9, 'Maximum 9 adults allowed')
    .required('At least one adult is required'),
  child: Yup.number()
    .min(0, 'Child count cannot be negative')
    .max(8, 'Maximum 8 children allowed'),
  infant: Yup.number()
    .min(0, 'Infant count cannot be negative')
    .max(8, 'Maximum 8 infants allowed')
    .test(
      'infants-not-more-than-adults',
      'Number of infants cannot be greater than the number of adults',
      function (infant) {
        const { adult } = this.parent;
        return infant <= adult;
      },
    ),
  departureDate: Yup.string()
    .required('This field is required')
    .test(
      'valid-date-format',
      'Departure date must be in YYYYMMDD format',
      (date) => moment(date, 'YYYYMMDD', true).isValid(),
    )
    .test('not-in-past', 'Departure date cannot be in the past', (date) =>
      moment(date, 'YYYYMMDD').isSameOrAfter(moment(), 'day'),
    ),
  returnDate: Yup.string().when('tripSearchType', {
    is: TripTypeSearchFormEnum.ROUND_TRIP,
    then: Yup.string()
      .required('This field is required for round-trip')
      .test(
        'valid-date-format',
        'Return date must be in YYYYMMDD format',
        (date) => moment(date, 'YYYYMMDD', true).isValid(),
      )
      .test(
        'after-departure',
        'Return date must be the same or after the departure date',
        function (returnDate) {
          const { departureDate } = this.parent;
          return moment(returnDate, 'YYYYMMDD').isSameOrAfter(
            moment(departureDate, 'YYYYMMDD'),
            'day',
          );
        },
      ),
    otherwise: Yup.string().nullable(),
  }),
  cabinClass: Yup.string().required('Cabin class is required'),
  pft: Yup.string().required('PFT is required'),
  isDirectFlight: Yup.boolean().required('Direct flight selection is required'),
  tripSearchType: Yup.string()
    .oneOf(
      [TripTypeSearchFormEnum.ONE_WAY, TripTypeSearchFormEnum.ROUND_TRIP],
      'Invalid trip search type',
    )
    .required('Trip search type is required'),
});

interface ISearchFrom {
  searchQuery: TSearch;
  setNewSearchParameter: (newData: TSearch) => void;
}

const SearchFrom: FC<ISearchFrom> = (props) => {
  const { searchQuery, setNewSearchParameter } = props;

  const initialValues = {
    to: searchQuery.to,
    from: searchQuery.from,
    adult: searchQuery.adult,
    child: searchQuery.child,
    infant: searchQuery.infant,
    departureDate: searchQuery.departureDate,
    returnDate: searchQuery.returnDate,
    cabinClass: searchQuery.cabinClass,
    pft: searchQuery.pft,
    isDirectFlight: searchQuery.isDirectFlight,
    tripSearchType: searchQuery.tripSearchType,
  };

    const handleSwapAirport = (
      values: TSearch,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
      ) => void,
    ) => {
      const fromAirport = values.from;
      const toAirport = values.to;

      setFieldValue('from', toAirport, false);
      setFieldValue('to', fromAirport, false);
    };

    return (
      <div className='container py-8'>
        <Formik
          initialValues={initialValues}
          validationSchema={SearchFormSchema}
          enableReinitialize={false}
          onSubmit={(value) => {
            setNewSearchParameter(value);
          }}
        >
          {({ values, setFieldValue, errors }: FormikProps<any>) => (
            <Form>
              <>
                <div className='grid md:grid-cols-6 xl:grid-cols-8 gap-5'>
                  <div className='col-span-7 md:col-span-2 xl:col-span-1'>
                    <div className='bg-white/20 rounded pr-4'>
                      <label
                        htmlFor='from'
                        className='block text-secondary-fg/50 text-xs font-medium tracking-wider px-3 pt-2'
                      >
                        TRIP TYPE
                      </label>
                      <Field
                        as='select'
                        name='tripSearchType'
                        type='text'
                        id='cabin-class'
                        className='w-full bg-transparent px-2  pb-2 text-secondary-fg text-base leading-6 font-semibold mt-1 text-ellipsis whitespace-nowrap overflow-hidden outline-none'
                      >
                        <option value='' disabled>
                          Select Trip Type
                        </option>
                        {Object.values(TripTypeSearchFormEnum).map(
                          (tripSearchType) => (
                            <option key={tripSearchType} value={tripSearchType}>
                              {`${getTripTypeLabel(tripSearchType)}`}
                            </option>
                          ),
                        )}
                      </Field>
                      <div className='form-error'>
                        <ErrorMessage name='tripSearchType' />
                      </div>
                    </div>
                  </div>

                  <div className='col-span-7 md:col-span-4 xl:col-span-2'>
                    <div className='flex gap-5 relative'>
                      <div className='flex-1'>
                        <div className='bg-white/20 rounded'>
                          <label
                            htmlFor='from'
                            className='block text-secondary-fg/50 text-xs font-medium tracking-wider px-3 pt-2'
                          >
                            FROM
                          </label>
                          <AirportSearchField
                            id='from'
                            className='w-full bg-transparent px-3 pb-2 text-secondary-fg text-base leading-6 font-semibold mt-1 text-ellipsis whitespace-nowrap overflow-hidden outline-none'
                            placeholder='Select From Airport'
                            selectedAirport={values.from}
                            onSelectedAirportChange={(airport: any) =>
                              setFieldValue('from', airport)
                            }
                          />
                        </div>
                        <div className='form-error text-secondary-fg/60'>
                          <ErrorMessage name='from' />
                        </div>
                      </div>
                      <div className='absolute top-[10px] left-[50%] -translate-x-[50%] z-[9]'>
                        <div
                          className='bg-white/20 text-primary w-10 h-10 rounded-full flex justify-center items-center mx-auto cursor-pointer border-2 border-secondary'
                          onClick={() =>
                            handleSwapAirport(values, setFieldValue)
                          }
                        >
                          <SwapIcon height={16} />
                        </div>
                      </div>
                      <div className='flex-1'>
                        <div className='bg-white/20 rounded'>
                          <label
                            htmlFor='to'
                            className='block text-secondary-fg/50 text-xs font-medium tracking-wider px-3 pt-2'
                          >
                            TO
                          </label>
                          <AirportSearchField
                            id='to'
                            className='w-full bg-transparent px-3 pb-2 text-secondary-fg text-base leading-6 font-semibold mt-1 text-ellipsis whitespace-nowrap overflow-hidden outline-none'
                            placeholder='Select To Airport'
                            selectedAirport={values.to}
                            onSelectedAirportChange={(airport: any) =>
                              setFieldValue('to', airport)
                            }
                          />
                        </div>
                        <div className='form-error text-secondary-fg/60'>
                          <ErrorMessage name='to' />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-span-7 md:col-span-2 xl:col-span-1'>
                    <div className='bg-white/20 rounded'>
                      <label
                        htmlFor='departureDate'
                        className='block text-secondary-fg/50 text-xs font-medium tracking-wider px-3 pt-2'
                      >
                        DEPART
                      </label>
                      <DatePicker
                        className='w-full bg-transparent px-3 pb-2 text-secondary-fg text-base leading-6 font-semibold mt-1 text-ellipsis whitespace-nowrap overflow-hidden cursor-pointer  outline-none'
                        value={values.departureDate}
                        onChange={(val) =>
                          setFieldValue(
                            'departureDate',
                            moment(val).format(SYSTEM_DATE_FORMAT),
                          )
                        }
                        minDate={new Date()}
                        format='DD MM YYYY'
                      />
                    </div>
                    <div className='form-error text-secondary-fg/60'>
                      <ErrorMessage name='departureDate' />
                    </div>
                  </div>

                  <>
                    <div className='col-span-7 md:col-span-2 xl:col-span-1'>
                      <div className='bg-white/20 rounded'>
                        <label
                          htmlFor='returnDate'
                          className='block text-secondary-fg/50 text-xs font-medium tracking-wider px-3 pt-2'
                        >
                          RETURN
                        </label>

                        {values.tripSearchType ===
                        TripTypeSearchFormEnum.ROUND_TRIP ? (
                          <DatePicker
                            className='w-full bg-transparent px-3 pb-2 text-secondary-fg text-base leading-6 font-semibold mt-1 text-ellipsis whitespace-nowrap overflow-hidden cursor-pointer  outline-none'
                            value={values.returnDate}
                            onChange={(val) =>
                              setFieldValue(
                                'returnDate',
                                moment(val).format(SYSTEM_DATE_FORMAT),
                              )
                            }
                            minDate={new Date()}
                            format='DD MM YYYY'
                          />
                        ) : (
                          <div className='text-secondary-fg text-base leading-6 font-semibold mt-1 opacity-50 whitespace-nowrap overflow-hidden text-ellipsis px-3 pb-2'>
                            Select Return
                          </div>
                        )}
                      </div>
                      <div className='form-error text-secondary-fg/60'>
                        <ErrorMessage
                          className='text-blue bg-green-500'
                          name='returnDate'
                        />
                      </div>
                    </div>
                  </>

                  <div className='col-span-7 md:col-span-2 xl:col-span-1'>
                    <div className='bg-white/20 rounded'>
                      <Dropdown
                        target={
                          <>
                            <label className='block text-secondary-fg/50 text-xs font-medium tracking-wider px-3 pt-2'>
                              PASSENGERS
                            </label>
                            <div className='text-secondary-fg text-base leading-6 font-semibold mt-1 text-ellipsis whitespace-nowrap overflow-hidden px-3 pb-2'>
                              {values.adult} Adult, {values.child} Children,{' '}
                              {values.infant} Infant
                            </div>
                          </>
                        }
                        menu={
                          <DropdownList>
                            <div className='flex px-8 py-2 gap-x-5'>
                              <div className='w-full'>
                                <label
                                  htmlFor='adult'
                                  className='form-label text-secondary-fg'
                                >
                                  Adult
                                </label>
                                <div className='w-full'>
                                  <Field
                                    id='adult'
                                    as='select'
                                    name='adult'
                                    className='form-field'
                                  >
                                    {generateNumberArray(1, 9).map((val) => {
                                      return (
                                        <option key={val} value={val}>
                                          {val}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                </div>
                              </div>
                              <div className='w-full'>
                                <div className='relative'>
                                  <label
                                    htmlFor='children'
                                    className='form-label text-secondary-fg'
                                  >
                                    Children
                                  </label>
                                  <Field
                                    id='children'
                                    as='select'
                                    name='child'
                                    className='form-field'
                                  >
                                    {generateNumberArray(0, 8).map((val) => {
                                      return (
                                        <option key={val} value={val}>
                                          {val}
                                        </option>
                                      );
                                    })}
                                  </Field>

                                  <div className='text-sm font-normal mt-1 text-secondary-fg opacity-50 top-[7em] left-[1em]'>
                                    2 - 11 Years
                                  </div>
                                </div>
                              </div>
                              <div className='w-full'>
                                <div className='relative'>
                                  <label
                                    htmlFor='infants'
                                    className='form-label text-secondary-fg'
                                  >
                                    Infants
                                  </label>

                                  <Field
                                    id='infants'
                                    as='select'
                                    name='infant'
                                    className='form-field'
                                  >
                                    {generateNumberArray(0, 9).map((val) => {
                                      return (
                                        <option key={val} value={val}>
                                          {val}
                                        </option>
                                      );
                                    })}
                                  </Field>

                                  <div className='text-sm font-normal mt-1 text-secondary-fg opacity-50 top-[7em]'>
                                    Below 2 Years
                                  </div>
                                </div>
                              </div>
                            </div>
                          </DropdownList>
                        }
                      />
                    </div>
                    <div className='form-error text-secondary-fg/60'>
                      <ErrorMessage name='adult' />
                    </div>
                    <div className='form-error text-secondary-fg/60'>
                      <ErrorMessage name='child' />
                    </div>
                    <div className='form-error text-secondary-fg/60'>
                      <ErrorMessage name='infant' />
                    </div>
                  </div>
                  <div className='col-span-7 md:col-span-2 xl:col-span-1'>
                    <div className='bg-white/20 rounded pr-4'>
                      <label
                        htmlFor='from'
                        className='block text-secondary-fg/50 text-xs font-medium tracking-wider px-3 pt-2'
                      >
                        Cabin
                      </label>
                      <Field
                        as='select'
                        name='cabinClass'
                        type='text'
                        id='cabin-class'
                        className='w-full bg-transparent px-2  pb-2 text-secondary-fg text-base leading-6 font-semibold mt-1 text-ellipsis whitespace-nowrap overflow-hidden outline-none'
                      >
                        <option value='' disabled>
                          Select Cabin
                        </option>
                        {Object.values(CabinClassEnum).map((cabinClass) => (
                          <option key={cabinClass} value={cabinClass}>
                            {`${getCabinClassLabel(cabinClass)}`}
                          </option>
                        ))}
                      </Field>
                      <div className='form-error'>
                        <ErrorMessage name='cabinClass' />
                      </div>
                    </div>
                  </div>
                  <div className='col-span-7 md:col-span-2 xl:col-span-1'>
                    <button
                      className='btn btn-primary min-h-[58px] w-full py-3 text-base'
                      type='submit'
                    >
                      Modify Search
                    </button>
                  </div>
                </div>
              </>
            </Form>
          )}
        </Formik>
      </div>
    );
};

export default SearchFrom;
