import { FC, useState } from 'react';
import {
  formatMinutesToTime,
  formatTime,
  SYSTEM_DATE_FORMAT,
} from 'app/utils/date-utils';
import { TFlightOption } from '../../types/FlightsList';
import { formatCurrency } from 'app/utils/currency-utils';
import {
  TFareSupplierFlightSchedule,
  TFareSupplierSegment,
} from '../../types/segment.type';
import { useSelector } from 'react-redux';
import { TRootState } from 'app/store';
import { RenderFlightNumber } from '../../utils/common.utils';
import { calculateStops, getFormattedStops } from 'app/utils/flight-utils';
import { TMasterSearchFareSupplierPriceItem } from '../../types/fare-supplier-search-response.type';
import { AirlineLogo } from 'app/modules/shared/Components';
import TravelDurationAndStopsBar from 'app/modules/shared/Components/TravelDurationAndStopsBar/TravelDurationAndStopsBar';
import moment from 'moment';

type IFlightCard = {
  flightOption: TFlightOption;
  handleSelect: (
    flight: TFlightOption,
    selectedPriceListItem: TMasterSearchFareSupplierPriceItem,
  ) => void;
  selectedFlight: string;
};

const FlightCard: FC<IFlightCard> = (props) => {
  const { user } = useSelector((state: TRootState) => state.auth);
  const displayFirst = user.agency.fareDisplayCount;

  const { flightOption, handleSelect, selectedFlight } = props;
  const [priceListIndex, setPriceListIndex] = useState<number>(displayFirst);

  const togglePriceListIndex = () => {
    if (priceListIndex !== displayFirst) setPriceListIndex(displayFirst);
    else setPriceListIndex(flightOption.priceList.length);
  };

  return (
    <div className='card p-6 '>
      <div className='grid grid-cols-5'>
        <div className='col-span-3 flex flex-col gap-5'>
          <div className='flex gap-3 '>
            <AirlineLogo
              src={flightOption.Flights[0].logo}
              alt={`${flightOption.Flights[0].name}`}
            />
            <div className=''>
              <div className='text-base leading-5 font-semibold'>
                {/* {getFlightNames()} */}
                {flightOption.Flights[0].name}
              </div>
              <div className='rounded-full bg-[#C8C8C8] h-1 w-1 '></div>
              <div className='text-xs leading-normal font-medium text-black/70'>
                {/* {getFlightNumbers()} */}
                {RenderFlightNumber(flightOption.Flights)}
              </div>
            </div>
          </div>

          <FlightSpan
            from={flightOption.from}
            to={flightOption.to}
            segments={flightOption.segments}
            duration={1450}
          />
        </div>

        <div className='col-span-2 '>
          <ul className='space-y-3'>
            {flightOption.priceList.slice(0, priceListIndex).map((price, i) => (
              <li key={i}>
                <div className='flex items-center gap-2'>
                  <input
                    id={price.id.toString()}
                    type='radio'
                    className='form-radio'
                    checked={price.id === selectedFlight}
                    onChange={() => handleSelect(flightOption, price)}
                  />
                  {/* <div className="line-through text-sm text-black/70">₹5,800</div> */}
                  <label
                    className=' leading-6 font-bold flex gap-2 items-center'
                    // htmlFor={`${index}priceRadio${i}`}
                  >
                    {/* [{price.combinationIdentifier}] */}
                    <span className='whitespace-nowrap'>
                      {formatCurrency(price.fareDescription.total.totalFare)}
                      {/* {formatCurrency(price.totalFare)} */}
                      {/* <PriceWithHiddenSupplier
                              totalFare={price.totalFare}
                              fareSupplier={price.fareSupplier}
                            /> */}
                    </span>
                    {price.isOwn && (
                      <div className='bg-green-500 h-2 w-2 rounded-full'></div>
                    )}
                    {/* {showNetFare && (
                            <span className='text-[.6rem] text-slate-500 leading-4 '>{` | NET ${formatCurrency(
                              price.netFare,
                            )}`}</span>
                          )} */}
                  </label>
                </div>
                <div>
                  {/* {' '}
                  {price.matchingCombinationIdentifiers.map((x) => (
                    <span>{x} ,</span>
                  ))}{' '} */}
                </div>
                <div className='flex items-center text-gray-500 text-[0.65rem] font-semibold gap-2 mt-1'>
                  <div className='bg-[#ECAE0E] px-2 py-1 rounded-md text-black '>
                    {price.fareIdentifier}
                    {/* OFFER_FARE_WITH_PNR */}
                  </div>
                  {/* {price.isOwn && (
                                                    <div className="bg-green-500 h-2 w-2 rounded-full"></div>
                                                )} */}
                  <div>
                    {price.fareDescription.adult.refundableType}
                    {/* {capitalize(
                            price.fareDescription.adult.refundableType?.toLowerCase(),
                          )} */}
                    {/* Non Refundable */}
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {flightOption.priceList.length > displayFirst && (
            <div
              onClick={togglePriceListIndex}
              className='cursor-pointer  text-center text-[10px] font-medium mt-3 flex flex-col items-start -gap-0'
            >
              {/* <div>
                {priceListIndex === displayFirst
                  ? flightOption.priceList.length - displayFirst
                  : ''}
                {priceListIndex === displayFirst ? ' more fare types' : ''}
              </div> */}
              <div className='text-sm font-semibold leading-normal text-primary'>
                {priceListIndex === displayFirst ? 'Show all fares' : 'Hide'}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface IFlightSpan {
  from: TFareSupplierFlightSchedule;
  to: TFareSupplierFlightSchedule;
  segments: TFareSupplierSegment[];
  duration: number;
}

export const FlightSpan: FC<IFlightSpan> = (props) => {
  const { from, to, segments, duration } = props;

  const formatDateForBar = (dateValue: string) => {
    return moment(dateValue, SYSTEM_DATE_FORMAT).format('DD MMM YYYY');
  };

  return (
    <div className='flex gap-4 items-center'>
      <div>
        <div className='text-xs font-normal'>{from.airport.city}</div>
        <div className='text-lg leading-6 font-semibold'>
          {formatTime(from.time)}
        </div>
        <div className='text-xs font-normal'>{formatDateForBar(from.date)}</div>
      </div>
      <TravelDurationAndStopsBar duration={duration} segments={segments} />
      {/* <div className='flex flex-col items-center'>
        <div className='text-xs font-normal text-black/70'>
          {formatMinutesToTime(duration)}
        </div>
        <div className='bg-[#ecae0e] h-1 w-28 rounded-lg mt-1' />
        <div className='text-xs font-normal text-black/70 mt-1'>
          {getFormattedStops(calculateStops(segments))}
        </div>
      </div> */}
      <div>
        <div className='text-xs font-normal'>{to.airport.city}</div>
        <div className='text-lg leading-6 font-semibold'>
          {formatTime(to.time)}
        </div>
        <div className='text-xs font-normal'>{formatDateForBar(to.date)}</div>
      </div>
    </div>
  );
};

export default FlightCard;
