import { FC } from 'react';
import { IDateTab } from '../../types/date-tab';

interface ICloseDates {
  tabDates: Array<IDateTab>;
  handleDateChange: (newTravelDate: string) => void;
}

const CloseDates: FC<ICloseDates> = (props) => {
  const { tabDates, handleDateChange } = props;

  return (
    <div className='grid grid-cols-4 gap-2 my-2'>
      {tabDates.map((tabDate) => (
        <div
          onClick={() =>
            !tabDate.isSelected &&
            !tabDate.isSelected &&
            handleDateChange(tabDate.date.format('YYYYMMDD'))
          }
          key={tabDate.date.format('YYYYMMDD')}
          className={`col-span-1 card h-16  ${tabDate.isSelected ? 'cursor-default' : 'cursor-pointer'} `}
        >
          <div className='flex flex-col justify-center items-center gap-1  h-full'>
            <div className='text-xs leading-4 font-normal'>
              {tabDate.date.format('dddd')}
            </div>
            <div className='text-base leading-5 font-semibold'>
              {tabDate.date.format('DD MMM')}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CloseDates;
