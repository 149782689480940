import { DepartureTimeEnum } from 'app/enums';

export const timeFilterMap: Record<
  DepartureTimeEnum,
  { startTime: string; endTime: string }
> = {
  EARLY_MORNING: { startTime: '000000', endTime: '080000' },
  MORNING: { startTime: '080000', endTime: '120000' },
  AFTERNOON: { startTime: '120000', endTime: '160000' },
  EVENING: { startTime: '160000', endTime: '200000' },
  NIGHT: { startTime: '200000', endTime: '235959' },
};
