import { FC, useContext, useEffect, useState } from 'react';
import { IFlight } from 'app/types';
import FlightListItem from '../FlightListItem/FlightListItem';
import { useMutation } from '@tanstack/react-query';
import { addEventToBookingFlow } from 'app/api/services/booking-flow-service.service';
import FlightsContext from '../../context/flights-context';
import moment from 'moment';
import { SYSTEM_TIME_FORMAT } from 'app/utils/date-utils';
import { formatCurrency } from 'app/utils/currency-utils';
import { useSelector } from 'react-redux';
import { TRootState } from 'app/store';
import useEventLogging from 'app/hooks/use-event-logging';

interface FlightListProps {
  list: IFlight[];
}

const FlightList: FC<FlightListProps> = (props) => {
  const { list } = props;
  const { user } = useSelector((state: TRootState) => state.auth);

  const { bookingFlowId, subAgencyAdditionalMarkup, showNetFare } =
    useContext(FlightsContext);
  const { createEvent } = useEventLogging(bookingFlowId);

  // temporary solution needs to be worked out :: partail work on "no-flight-fix" branch
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  // addEventToBookingFlow;
  const handleCreateEvent = (payload: {
    priceListIndex: number;
    selectedPriceItemIndex: number;
    type: 'SELECTED' | 'BOOKED';
  }) => {
    const selectedPrice = list[payload.priceListIndex];
    const selectedPriceItem =
      list[payload.priceListIndex].priceList[payload.selectedPriceItemIndex];
    let message = `${user.name} ${payload.type === 'BOOKED' ? 'booked' : 'selected'}  ${selectedPrice.segments[0].flight.name} flight ${selectedPrice.segments[0].flight.code} ${selectedPrice.segments[0].flight.number} Dep ${moment(selectedPrice.departure.time, SYSTEM_TIME_FORMAT).format('HH:mm')}, Arv ${moment(selectedPrice.arrival.time, SYSTEM_TIME_FORMAT).format('HH:mm')} @ Rs ${formatCurrency(selectedPriceItem.totalFare + subAgencyAdditionalMarkup)} | T.F ${formatCurrency(selectedPriceItem.totalFare)} | Add. Markup ${formatCurrency(subAgencyAdditionalMarkup)} `;

    createEvent({
      message,
    });
  };

  if (!show) return null;

  if (list.length === 0)
    return <p className='mt-12 text-center text-lg'>No flights found</p>;

  return (
    <div className='mt-6'>
      <div className='hidden md:grid card mb-1 rounded-b-none grid-cols-12 px-7 py-3 '>
        <div className='col-span-5 text-sm font-semibold'>Flights Details</div>
        {/* <div className='col-span-5'></div> */}
        <div className='col-span-7 grid grid-cols-7'>
          <div className='col-span-2 text-sm font-semibold'>Published Fare</div>
          <div className='col-span-2 text-sm font-semibold'>
            {showNetFare && 'Net Fare'}
          </div>
          <div className='col-span-3 text-sm font-semibold'>Fare Type</div>
        </div>
      </div>
      {list.map((row, i) => (
        <FlightListItem
          key={i}
          index={i}
          createEvent={handleCreateEvent}
          {...row}
        />
      ))}
    </div>
  );
};

export default FlightList;
