import { TComboAppliedFilter } from 'app/modules/ReturnFlights/types/combo-applied-filter';
import {
  TComboFlightOption,
  TComboOneWay,
} from 'app/modules/ReturnFlights/types/FlightsList';

const checkValidDeparturePeriod = (
  selectedAirlines: Array<string>,
  FlightDeparturePeriod: TComboOneWay['flights'],
): boolean => {
  if (selectedAirlines.length === 0) return true;

  return selectedAirlines.includes(FlightDeparturePeriod[0].code);
};

export const filterComboFlightsByAirline = (
  comboFlights: Array<TComboFlightOption>,
  filter: Pick<TComboAppliedFilter, 'airlines'>,
): Array<TComboFlightOption> => {
  const { airlines } = filter;
  if (airlines.OUTBOUND.length === 0 && airlines.INBOUND.length === 0)
    return [...comboFlights];

  const AirlineFilteredFlights: Array<TComboFlightOption> = [];

  comboFlights.forEach((comboFlight) => {
    // comboFlight.OUTBOUND.flights[0].code;
    if (
      checkValidDeparturePeriod(
        airlines.OUTBOUND,
        comboFlight.OUTBOUND.flights,
      ) &&
      checkValidDeparturePeriod(airlines.INBOUND, comboFlight.INBOUND.flights)
    ) {
      AirlineFilteredFlights.push({ ...comboFlight });
    }
  });

  return AirlineFilteredFlights;
};
