import { FC, useState } from 'react';
import FlightDetails from '../../FlightDetails/FlightDetails';
import { TFlightOptionSelected } from 'app/modules/ReturnFlights/types/FlightsList';
import BaggageInformation from '../../BaggageInformation/BaggageInformation';

interface ISelectedFlightDetail {
  flight: TFlightOptionSelected;
}

const SelectedFlightDetail: FC<ISelectedFlightDetail> = (props) => {
  const { flight } = props;
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const sectionHeadings = [
    'Flight Details',
    'Fare Summary',
    'Fare Rules',
    'Baggage Information',
  ];

  return (
    <div className='border border-gray-100 rounded-md mt-4'>
      <ul className='flex border-b border-gray-100 text-[0.9rem]'>
        {sectionHeadings.map((item, i) => (
          <li key={i} className='relative'>
            <button
              className={[
                'block px-4 py-3 cursor-pointer',
                selectedTab === i ? 'text-primary font-medium' : '',
              ].join(' ')}
              onClick={() => setSelectedTab(i)}
            >
              {item}
            </button>
            {selectedTab === i && (
              <div className='absolute w-full h-[2px] bg-primary bottom-0' />
            )}
          </li>
        ))}
      </ul>
      <div className='py-2 px-2 overflow-auto'>
        {selectedTab === 0 && <FlightDetails segments={flight.segments} />}
        {selectedTab === 1 && <div> Fare Summary </div>}
        {selectedTab === 2 && <div> Fare Rules </div>}
        {selectedTab === 3 && (
          <BaggageInformation
            fareDescription={flight.pricingDetail.fareDescription}
          />
        )}
      </div>
    </div>
  );
};

export default SelectedFlightDetail;

//   <div className='py-2 px-2 overflow-auto'>
//     {/*Flight Details  */}
//     {selectedTab === 0 && <FlightDetails segments={segments} />}

//     {/* Fare Summary */}
//     {selectedTab === 1 && (
//       <FareSummary selectedPriceItem={selectedPriceItem} />
//     )}

//     {/* Fare Rules  */}
//     {selectedTab === 2 && (
//       <div className='px-4 py-2'>
//         <FareRules selectedPriceItem={selectedPriceItem} />
//       </div>
//     )}

//     {/* Baggage information */}
//     {selectedTab === 3 && (
//       <BaggageInformation selectedPriceItem={selectedPriceItem} />
//     )}
//   </div>;
