import { useEffect, useState } from 'react';
import {
  TAirlineOption,
  TFlightFilter,
  TPartOfDay,
} from '../types/flight-filter';
import { TFlightOption } from '../types/FlightsList';

const initialState: TFlightFilter = {
  airlines: [],
  PriceRange: {
    min: 0,
    max: 0,
  },
  stops: {
    NON_STOP: 0,
    ONE_STOP: 0,
    MULTIPLE_STOP: 0,
  },
  partsOfDateCount: {
    AFTERNOON: 0,
    EARLY_MORNING: 0,
    EVENING: 0,
    MORNING: 0,
    NIGHT: 0,
  },
};

type TUseCreateFilter = {
  flights: Array<TFlightOption>;
};

export const useCreateFilter = (props: TUseCreateFilter): TFlightFilter => {
  const { flights } = props;

  const [filter, setFilter] = useState<TFlightFilter>(initialState);

  const createFilter = (
    availableFlights: Array<TFlightOption>,
  ): TFlightFilter => {
    // ) => {
    const airlines: Record<string, TAirlineOption> = {};
    let minPrice: number = 0;
    let maxPrice: number = 0;

    let nonStop = 0;
    let oneStop = 0;
    let multiStop = 0;

    const partsOfDateCount: TPartOfDay = {
      AFTERNOON: 0,
      EARLY_MORNING: 0,
      EVENING: 0,
      MORNING: 0,
      NIGHT: 0,
    };

    availableFlights.forEach((availableFlight) => {
      partsOfDateCount[availableFlight.outBoundDeparture]++;

      //  create airline filter data
      const airlineCode = availableFlight.segments[0].flight.code;

      if (!airlines.hasOwnProperty(airlineCode)) {
        airlines[airlineCode] = {
          airline: availableFlight.segments[0].flight,
          count: 1,
          minPrice:
            availableFlight.priceList[0].fareDescription.total.totalFare,
          maxPrice:
            availableFlight.priceList[availableFlight.priceList.length - 1]
              .fareDescription.total.totalFare,
        };
      } else {
        // increment to available option
        airlines[airlineCode].count = airlines[airlineCode].count + 1;

        // check and if set min price available for the airline
        if (
          airlines[airlineCode].minPrice >
          availableFlight.priceList[0].fareDescription.total.totalFare
        ) {
          airlines[airlineCode].minPrice =
            availableFlight.priceList[0].fareDescription.total.totalFare;
        }

        // check and if set man price available for the airline
        if (
          airlines[airlineCode].maxPrice <
          availableFlight.priceList[availableFlight.priceList.length - 1]
            .fareDescription.total.totalFare
        ) {
          airlines[airlineCode].maxPrice =
            availableFlight.priceList[
              availableFlight.priceList.length - 1
            ].fareDescription.total.totalFare;
        }
      }

      // min and max price
      Object.values(airlines).forEach((airlineOption, index) => {
        if (index === 0) {
          minPrice = airlineOption.minPrice;
          maxPrice = airlineOption.maxPrice;
          return;
        }
        minPrice = Math.min(minPrice, airlineOption.minPrice);
        maxPrice = Math.max(maxPrice, airlineOption.maxPrice);
      });

      // calculate stops
      const segmentLength = availableFlight.Flights.length;

      if (segmentLength === 1) {
        nonStop++;
      } else if (segmentLength === 2) {
        oneStop++;
      } else {
        multiStop++;
      }
    });

    return {
      airlines: Object.values(airlines),
      PriceRange: {
        min: minPrice,
        max: maxPrice,
      },
      stops: {
        NON_STOP: nonStop,
        ONE_STOP: oneStop,
        MULTIPLE_STOP: multiStop,
      },
      partsOfDateCount,
    };
  };

  useEffect(() => {
    if (flights.length === 0) {
      setFilter(initialState);
      return;
    }

    setFilter(createFilter(flights));
  }, [flights]);

  return filter;
};
