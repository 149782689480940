import { DepartureTimeEnum } from 'app/enums';
import { TComboAppliedFilter } from 'app/modules/ReturnFlights/types/combo-applied-filter';
import { TComboFlightOption } from 'app/modules/ReturnFlights/types/FlightsList';

const checkValidDeparturePeriod = (
  selectedDeparturePeriods: Array<DepartureTimeEnum>,
  FlightDeparturePeriod: DepartureTimeEnum,
): boolean => {
  if (selectedDeparturePeriods.length === 0) return true;

  return selectedDeparturePeriods.includes(FlightDeparturePeriod);
};

export const filterComboFlightsByDeparturePeriod = (
  comboFlights: Array<TComboFlightOption>,
  filter: Pick<TComboAppliedFilter, 'departureTimes'>,
): Array<TComboFlightOption> => {
  const { departureTimes } = filter;
  if (
    departureTimes.OUTBOUND.length === 0 &&
    departureTimes.INBOUND.length === 0
  )
    return [...comboFlights];

  const timePeriodFilteredFlight: Array<TComboFlightOption> = [];

  comboFlights.forEach((comboFlight) => {
    if (
      checkValidDeparturePeriod(
        departureTimes.OUTBOUND,
        comboFlight.OUTBOUND.departureTiming,
      ) &&
      checkValidDeparturePeriod(
        departureTimes.INBOUND,
        comboFlight.INBOUND.departureTiming,
      )
    ) {
      timePeriodFilteredFlight.push({ ...comboFlight });
    }
  });

  return timePeriodFilteredFlight;

  // if (filter.departureTimes.length === 0) {
  //   timeFilteredFlight = [...stopFilterFlights];
  // } else {
  //   stopFilterFlights.forEach((flight) => {
  //     if (filter.departureTimes.includes(flight.outBoundDeparture)) {
  //       timeFilteredFlight.push(flight);
  //     }
  //   });
  // }
};
