import produce from 'immer';
import { cloneDeep } from 'lodash';
import { TFlightOption } from '../types/FlightsList';
import { getStopsEnum, getTimeOfDay } from '../hooks/useReturnLogic';
import {
  TMasterFareSupplierSearchResult,
  TMasterSearchFareSupplierPriceItem,
} from '../types/fare-supplier-search-response.type';

// Removes duplicate flight and  entry for pricing in priceList
export const normalizeFlightData = (
  res: Array<TMasterFareSupplierSearchResult>,
): Array<TMasterFareSupplierSearchResult> => {
  const mergedFlights: Record<string, TMasterFareSupplierSearchResult> = {};
  const notMergedFlightList = cloneDeep(res);
  notMergedFlightList.forEach((notMergedFlight) => {
    if (notMergedFlight.segments.length > 0) {
      const key = `${
        notMergedFlight.segments[0].flight.code
      }${notMergedFlight.segments[0].flight.number.trim()}-${notMergedFlight.segments[0].departure.date}${
        notMergedFlight.segments[0].departure.time
      }-${notMergedFlight.segments[notMergedFlight.segments.length - 1].arrival.date}${
        notMergedFlight.segments[notMergedFlight.segments.length - 1].arrival
          .time
      }`;
      if (mergedFlights[key]) {
        mergedFlights[key].priceList.push(...notMergedFlight.priceList);
      } else {
        mergedFlights[key] = notMergedFlight;
      }
    }
  });
  const data = Object.values(mergedFlights);
  return data;
};

export const sortPriceList = (
  flights: Array<TMasterFareSupplierSearchResult>,
): Array<TMasterFareSupplierSearchResult> => {
  let clonedFlights = produce(flights, (draftFlights) => draftFlights);

  const sortedArrayData: Array<TMasterFareSupplierSearchResult> = [];

  clonedFlights.forEach((flightOption) => {
    sortedArrayData.push({
      ...flightOption,
      priceList: [...flightOption.priceList].sort(
        (item, successorItem) =>
          +item.fareDescription.total.totalFare -
          +successorItem.fareDescription.total.totalFare,
      ),
    });
  });

  return sortedArrayData;
};

// converts  TFlightOption[]  to   TMasterFareSupplierSearchResult[]>,
export const optimizeFlightData = (
  flightOptions: Array<TMasterFareSupplierSearchResult>,
): Array<TFlightOption> => {
  const getMinMaxPrice = (
    priceList: Array<TMasterSearchFareSupplierPriceItem>,
  ): { minPrice: number; maxPrice: number } => {
    let minPrice = 0;
    let maxPrice = 0;

    priceList.forEach((price, index) => {
      if (index === 0) {
        minPrice = price.fareDescription.total.totalFare;
        maxPrice = price.fareDescription.total.totalFare;
      }

      if (minPrice > price.fareDescription.total.totalFare) {
        minPrice = price.fareDescription.total.totalFare;
      }

      if (maxPrice < price.fareDescription.total.totalFare) {
        maxPrice = price.fareDescription.total.totalFare;
      }
    });

    return {
      maxPrice,
      minPrice,
    };
  };

  return flightOptions.map((flightOption) => ({
    priceList: flightOption.priceList,
    segments: flightOption.segments,
    from: flightOption.segments[0].departure,
    to: flightOption.segments[flightOption.segments.length - 1].arrival,
    Flights: flightOption.segments.map((segment) => segment.flight),
    stops: getStopsEnum(flightOption.segments.length),
    outBoundDeparture: (() => {
      let x = getTimeOfDay(flightOption.segments[0].departure.time);
      return x;
    })(),
    ...getMinMaxPrice(flightOption.priceList),
  }));
};

export const sortByPricing = (
  flightOptions: Array<TFlightOption>,
): Array<TFlightOption> => {
  let clonedFlights = produce(flightOptions, (draftFlights) => draftFlights);
  let sorted = [...clonedFlights].sort(
    (item, successorItem) => item.minPrice - +successorItem.minPrice,
  );
  return sorted;
};
