import { TFareSupplierSegment } from 'app/modules/ReturnFlights/types/segment.type';
import { formatMinutesToTime } from 'app/utils/date-utils';
import { calculateStops, getFormattedStops } from 'app/utils/flight-utils';
import { ComponentPropsWithoutRef } from 'react';

interface ITravelDurationAndStopsBar extends ComponentPropsWithoutRef<'div'> {
  duration: number; // Duration is in minutes
  segments: Array<TFareSupplierSegment>;
}

const TravelDurationAndStopsBar = (props: ITravelDurationAndStopsBar) => {
  const { duration, segments } = props;

  return (
    <div className='flex flex-col items-center mt-1'>
      <div className='text-xs font-normal text-black/70'>
        {formatMinutesToTime(duration)}
      </div>
      <div className='relative bg-[#ECAE0E] h-1 w-40 rounded-lg mt-1 before:absolute before:top-1/2 before:-translate-y-1/2 before:left-0 before:w-6 before:h-2 before:bg-[#ECAE0E] before:rounded-md after:absolute after:top-1/2 after:-translate-y-1/2 after:right-0 after:w-6 after:h-2 after:bg-[#ECAE0E] after:rounded-full' />
      <div className='text-xs font-normal text-black/70 mt-1'>
        {getFormattedStops(calculateStops(segments))}
      </div>
    </div>
  );
};

export default TravelDurationAndStopsBar;
