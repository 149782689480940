import { TSearch } from '../types/search';
import { useEffect, useState } from 'react';
import { pipeline } from '../utils/common.utils';
import useSearchHandler from './useSearchHandler';
import { TComboFlightOption, TFlightOption } from '../types/FlightsList';
import { TripTypeEnum } from 'app/enums/trip-type.enum';

import { timeFilterMap } from '../config/filter-time';
import { DepartureTimeEnum, StopTypeEnum } from 'app/enums';
import {
  normalizeFlightData,
  optimizeFlightData,
  sortByPricing,
  sortPriceList,
} from '../utils/return-pipeline-steps';
import { filterSegmentsAndCreateOptimizedCombo } from './comboSearhPipeline';
// import { filterSegmentsAndCreateOptimizedCombo } from './comboSearhPipeline';

type IUseReturnLogic = {
  searchQuery: TSearch;
  tripType: TripTypeEnum;
};

export const getStopsEnum = (stops: number): StopTypeEnum => {
  if (stops === 1) {
    return StopTypeEnum.NON_STOP;
  } else if (stops === 2) {
    return StopTypeEnum.ONE_STOP;
  } else {
    return StopTypeEnum.MULTIPLE_STOP;
  }
};
export const getTimeOfDay = (time: number | string): DepartureTimeEnum => {
  const numericTime = +time; // Convert time to a number once

  if (
    numericTime >= +timeFilterMap.EARLY_MORNING.startTime &&
    numericTime < +timeFilterMap.EARLY_MORNING.endTime
  ) {
    return DepartureTimeEnum.EARLY_MORNING;
  } else if (
    numericTime >= +timeFilterMap.MORNING.startTime &&
    numericTime < +timeFilterMap.MORNING.endTime
  ) {
    return DepartureTimeEnum.MORNING;
  } else if (
    numericTime >= +timeFilterMap.AFTERNOON.startTime &&
    numericTime < +timeFilterMap.AFTERNOON.endTime
  ) {
    return DepartureTimeEnum.AFTERNOON;
  } else if (
    numericTime >= +timeFilterMap.EVENING.startTime &&
    numericTime < +timeFilterMap.EVENING.endTime
  ) {
    return DepartureTimeEnum.EVENING;
  } else if (
    numericTime >= +timeFilterMap.NIGHT.startTime &&
    numericTime < +timeFilterMap.NIGHT.endTime
  ) {
    return DepartureTimeEnum.NIGHT;
  } else {
    throw new Error('Time of Day Issue');
  }
};

const steps = [
  normalizeFlightData,
  sortPriceList,
  optimizeFlightData,
  sortByPricing,
];

const useReturnLogic = (props: IUseReturnLogic) => {
  const { searchQuery, tripType } = props;

  const [outBoundFlight, setOutBoundFlights] = useState<Array<TFlightOption>>(
    [],
  );
  const [inBoundFlight, setInBoundFlights] = useState<Array<TFlightOption>>([]);

  const [comboFlight, setComboFlights] = useState<Array<TComboFlightOption>>(
    [],
  );

  const {
    isLoading,
    availableInBoundFlights,
    availableOutBoundFlights,
    availableComboFlights,
  } = useSearchHandler({
    searchQuery,
    tripType: tripType,
  });

  useEffect(() => {
    const process = pipeline(steps);
    const processedData = process(availableOutBoundFlights);
    // console.log({ processedData });
    setOutBoundFlights(processedData);
  }, [availableOutBoundFlights]);

  useEffect(() => {
    const process = pipeline(steps);
    const processedData = process(availableInBoundFlights);
    setInBoundFlights(() => processedData);
  }, [availableInBoundFlights]);

  useEffect(() => {
    setComboFlights(() =>
      filterSegmentsAndCreateOptimizedCombo(availableComboFlights),
    );
  }, [availableComboFlights]);

  return {
    isLoading,
    outBoundFlight,
    inBoundFlight,
    comboFlight,
  };
};

export default useReturnLogic;
