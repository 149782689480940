import { twMerge } from 'tailwind-merge';
import { ComponentPropsWithoutRef } from 'react';

interface IAirlineLogo extends ComponentPropsWithoutRef<'img'> {
  src: string;
  alt: string;
}

const AirlineLogo = (props: IAirlineLogo) => {
  const { src, alt, className, ...rest } = props;

  return (
    <img
      {...rest}
      crossOrigin='anonymous'
      className={twMerge('w-10 h-10 rounded-[10px]', className)}
      src={src}
      alt={alt}
    />
  );
};

export default AirlineLogo;
