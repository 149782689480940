import { TComboFlightOption } from 'app/modules/ReturnFlights/types/FlightsList';
import { TComboAppliedFilter } from 'app/modules/ReturnFlights/types/combo-applied-filter';

export const filterComboFlightsByPrice = (
  comboFlights: Array<TComboFlightOption>,
  filter: Pick<TComboAppliedFilter, 'priceLimit'>,
): Array<TComboFlightOption> => {
  const { priceLimit } = filter;

  const priceFilteredFlight: Array<TComboFlightOption> = [];

  const txComboFlight: TComboFlightOption[] = comboFlights.map(
    (comboFlight) => ({
      ...comboFlight,
      priceList: comboFlight.priceList.filter(
        (priceItem) => priceItem.fareDescription.total.totalFare <= priceLimit,
      ),
    }),
  );

  txComboFlight.forEach((comboFlight) => {
    if (comboFlight.priceList.length !== 0) {
      priceFilteredFlight.push({
        ...comboFlight,
      });
    }
  });

  return priceFilteredFlight;
};
