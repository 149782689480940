import moment from 'moment';
import { FC } from 'react';
import { FlightDirectionEnum } from 'app/enums/flight-direction.enum';
import LottieLoader from 'app/components/LottieLoader/LottieLoader';
import searchLoaderLottie from 'assets/lotties/search-loader.json';
import { TSearch } from '../../types/search';
import { IDateTab } from '../../types/date-tab';
import SearchHead from '../SearchHead';
import LegInitialLoading from './LegInitialLoading';

interface ILoadingSection {
  searchQuery: TSearch;
  TabDates: {
    outboundDates: Array<IDateTab>;
    inboundDates: Array<IDateTab>;
  };
}

const LoadingSection: FC<ILoadingSection> = (props) => {
  const { searchQuery, TabDates } = props;

  return (
    <div className=' col-span-8'>
      <div className='grid grid-cols-6 gap-9'>
        <SearchHead searchQuery={searchQuery} />
        <div className='col-span-3'>
          <LegInitialLoading
            from={searchQuery.from}
            to={searchQuery.to}
            date={moment(searchQuery.departureDate, 'YYYYMMDD')}
            flightDirection={FlightDirectionEnum.OUTBOUND}
            dateList={TabDates.outboundDates}
            // handleTravelDateChange={handleTravelDateChange}
          />
        </div>
        <div className='col-span-3'>
          <LegInitialLoading
            from={searchQuery.to}
            to={searchQuery.from}
            date={moment(searchQuery.returnDate, 'YYYYMMDD')}
            flightDirection={FlightDirectionEnum.INBOUND}
            dateList={TabDates.inboundDates}
            // flightOptions={inBoundFilteredFlight}
          />
        </div>
        <div className='col-span-6 -mt-9'>
          <div className='card p-7 pt-4 h-96'>
            <div className='text-center'>
              <p className='mt-12 text-lg font-semibold'>
                Hold on, we are searching for the best fares
              </p>
              <div className='w-1/2 mx-auto'>
                <LottieLoader animationData={searchLoaderLottie} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSection;
