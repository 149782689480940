import React, { Dispatch, FC, SetStateAction } from 'react';
import { TComboFlightFilter, TPartOfDay } from '../../types/flight-filter';
import { TComboAppliedFilter } from '../../types/combo-applied-filter';
import { PriceSlider } from '../Filter/PriceSlider';
import {
  AirlineFilterForm,
  DepartureFilterForm,
  StopFilterForm,
} from '../Filter/Filter';
import { DepartureTimeEnum, StopTypeEnum } from 'app/enums';
import { FlightDirectionEnum } from 'app/enums/flight-direction.enum';
import { flip } from '@floating-ui/react';

interface IComboFlightFilter {
  comboFlightFilterOptions: TComboFlightFilter;
  appliedComboFlightFilter: TComboAppliedFilter;
  setAppliedComboFlightFilter: Dispatch<SetStateAction<TComboAppliedFilter>>;
}

const ComboFlightFilter: FC<IComboFlightFilter> = (props) => {
  const {
    comboFlightFilterOptions,
    appliedComboFlightFilter,
    setAppliedComboFlightFilter,
  } = props;

  const addAirlineToFilter = (
    flightFlow: FlightDirectionEnum,
    airlineCode: string,
  ) => {
    setAppliedComboFlightFilter((prev) => ({
      ...prev,
      airlines: {
        ...prev.airlines,
        [flightFlow]: [...prev.airlines[flightFlow], airlineCode],
      },
    }));
  };

  const removeAirlineFromFilter = (
    flightFlow: FlightDirectionEnum,
    airlineCode: string,
  ) => {
    setAppliedComboFlightFilter((prev) => ({
      ...prev,
      airlines: {
        ...prev.airlines,
        [flightFlow]: prev.airlines[flightFlow].filter(
          (code: string) => code !== airlineCode,
        ),
      },
      // airlines: prev.airlines.filter((code: string) => code !== airlineCode),
    }));
  };

  const handlePriceLimitChange = (value: number) => {
    setAppliedComboFlightFilter((prev) => ({
      ...prev,
      priceLimit: value,
    }));
  };

  const addDepartureTimeToFilter = (
    flightFlow: FlightDirectionEnum,
    departureTime: DepartureTimeEnum,
  ) => {
    setAppliedComboFlightFilter((prev) => ({
      ...prev,
      departureTimes: {
        ...prev.departureTimes,
        [flightFlow]: [...prev.departureTimes[flightFlow], departureTime],
      },
    }));
  };

  const removeDepartureTimeFromFilter = (
    flightFlow: FlightDirectionEnum,
    departureTime: DepartureTimeEnum,
  ) => {
    setAppliedComboFlightFilter((prev) => ({
      ...prev,
      departureTimes: {
        ...prev.departureTimes,
        [flightFlow]: prev.departureTimes[flightFlow].filter(
          (code: string) => code !== departureTime,
        ),
      },
    }));
  };

  const addStopToFilter = (
    flightFlow: FlightDirectionEnum,
    stop: StopTypeEnum,
  ) => {
    setAppliedComboFlightFilter((prev) => ({
      ...prev,
      stops: {
        ...prev.stops,
        [flightFlow]: [...prev.stops[flightFlow], stop],
      },
    }));
  };

  const removeStopFromFilter = (
    flightFlow: FlightDirectionEnum,
    stop: StopTypeEnum,
  ) => {
    setAppliedComboFlightFilter((prev) => ({
      ...prev,
      stops: {
        ...prev.stops,
        [flightFlow]: prev.stops[flightFlow].filter(
          (code: string) => code !== stop,
        ),
      },

      // stops: prev.stops.filter((code: string) => code !== stop),
    }));
  };

  return (
    <div className='card p-6 sticky top-24  max-h-screen overflow-auto mb-24 no-scrollbar '>
      <div className='scroll-auto'>
        <PriceSlider
          priceRange={{
            min: comboFlightFilterOptions.PriceRange.min,
            max: comboFlightFilterOptions.PriceRange.max,
          }}
          value={appliedComboFlightFilter.priceLimit}
          setLimitValue={handlePriceLimitChange}
        />

        <AirlineFilterForm
          addAirlineToFilter={(airlineCode: string) =>
            addAirlineToFilter(FlightDirectionEnum.OUTBOUND, airlineCode)
          }
          removeAirlineFromFilter={(airlineCode: string) =>
            removeAirlineFromFilter(FlightDirectionEnum.OUTBOUND, airlineCode)
          }
          selectedAirline={appliedComboFlightFilter.airlines.OUTBOUND}
          airlineOptions={comboFlightFilterOptions.airlines.OUTBOUND || []}
        />

        <AirlineFilterForm
          addAirlineToFilter={(airlineCode: string) =>
            addAirlineToFilter(FlightDirectionEnum.INBOUND, airlineCode)
          }
          removeAirlineFromFilter={(airlineCode: string) =>
            removeAirlineFromFilter(FlightDirectionEnum.INBOUND, airlineCode)
          }
          selectedAirline={appliedComboFlightFilter.airlines.INBOUND}
          airlineOptions={comboFlightFilterOptions.airlines.INBOUND || []}
        />

        {/* departure outward filter form */}
        <DepartureFilterForm
          departureFrom='Outward'
          addDepartureTimeToFilter={(departureTime: DepartureTimeEnum) =>
            addDepartureTimeToFilter(
              FlightDirectionEnum.OUTBOUND,
              departureTime,
            )
          }
          departureTimesOptions={
            comboFlightFilterOptions?.partsOfDateCount?.OUTBOUND ||
            ({} as TPartOfDay)
          }
          removeDepartureTimeFromFilter={(departureTime: DepartureTimeEnum) =>
            removeDepartureTimeFromFilter(
              FlightDirectionEnum.OUTBOUND,
              departureTime,
            )
          }
          selectedDepartureTimes={
            appliedComboFlightFilter.departureTimes.OUTBOUND
          }
        />

        {/* departure Inbound filter form  */}
        <DepartureFilterForm
          departureFrom='Inbound'
          addDepartureTimeToFilter={(departureTime: DepartureTimeEnum) =>
            addDepartureTimeToFilter(FlightDirectionEnum.INBOUND, departureTime)
          }
          departureTimesOptions={
            comboFlightFilterOptions?.partsOfDateCount?.OUTBOUND ||
            ({} as TPartOfDay)
          }
          removeDepartureTimeFromFilter={(departureTime: DepartureTimeEnum) =>
            removeDepartureTimeFromFilter(
              FlightDirectionEnum.INBOUND,
              departureTime,
            )
          }
          selectedDepartureTimes={
            appliedComboFlightFilter.departureTimes.INBOUND
          }
        />

        {/* stops outwards  */}
        <StopFilterForm
          departureFrom='outward'
          addStopToFilter={(stop: StopTypeEnum) =>
            addStopToFilter(FlightDirectionEnum.OUTBOUND, stop)
          }
          removeStopFromFilter={(stop: StopTypeEnum) =>
            removeStopFromFilter(FlightDirectionEnum.OUTBOUND, stop)
          }
          selectedStops={appliedComboFlightFilter.stops.OUTBOUND}
          stopOptions={comboFlightFilterOptions.stops.OUTBOUND}
        />

        {/* stops outwards  */}
        <StopFilterForm
          departureFrom='inward'
          addStopToFilter={(stop: StopTypeEnum) =>
            addStopToFilter(FlightDirectionEnum.INBOUND, stop)
          }
          removeStopFromFilter={(stop: StopTypeEnum) =>
            removeStopFromFilter(FlightDirectionEnum.INBOUND, stop)
          }
          selectedStops={appliedComboFlightFilter.stops.INBOUND}
          stopOptions={comboFlightFilterOptions.stops.INBOUND}
        />
      </div>
    </div>
  );
};

export default ComboFlightFilter;
