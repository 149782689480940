import { useQuery } from '@tanstack/react-query';
import Footer from 'app/components/Footer/Footer';
import NavBarTwo from 'app/components/Navbar/NavBarTwo';
import http from 'app/config/http';
import { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Outlet, useLocation } from 'react-router-dom';

interface MainLayoutProps {}

const MainLayout: FC<MainLayoutProps> = () => {
  const [showTopNav, setShowTopNav] = useState(true);
  const location = useLocation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (isMobile) {
      setShowTopNav(false);
    } else {
      setShowTopNav(true);
    }
  }, [location.pathname, isMobile]);

  useEffect(() => {
    let root = document.documentElement;

    if (showTopNav) {
      root.style.setProperty('--navbar-height', '70px');
      root.style.setProperty('--navbar-height', '70px');
    } else {
      root.style.setProperty('--navbar-height', '0');
      root.style.setProperty('--navbar-height', '0');
    }
  }, [showTopNav]);

  // useEffect(() => {
  // if (window.location.hostname !== 'agents.travellove.in') {
  //   var Tawk_API: any = Tawk_API || {},
  //     Tawk_LoadStart = new Date();
  //   (function () {
  //     var s1 = document.createElement('script'),
  //       s0 = document.getElementsByTagName('script')[0];
  //     s1.async = true;
  //     s1.src = 'https://embed.tawk.to/664474f59a809f19fb311516/1httmhomn';
  //     s1.charset = 'UTF-8';
  //     s1.setAttribute('crossorigin', '*');
  //     s0.parentNode.insertBefore(s1, s0);
  //   })();
  // }
  // }, []);

  const tawkScriptQuery = useQuery(['tawk-to'], async () => {
    const { data } = await http.get(
      `${process.env.REACT_APP_API_URL}/api/v1/sub-agent/plugins/tawk-to-chat`,
    );
    return data;
  });

  useEffect(() => {
    if (tawkScriptQuery.data) {
      // const x = document.getElementById('tawk-container');
      // x.innerHTML = tawkScriptQuery.data.details.script;

      const div = document.createElement('div');
      div.innerHTML = tawkScriptQuery.data.details.script;
      const myScript = div.querySelector('script');
      const code = myScript.innerHTML;

      // try {
      //   eval(tawkScriptQuery.data.details.script);
      // } catch (e) {
      //   console.log('Tawk Failid', e);
      // }

      try {
        eval(code);
      } catch (e) {
        console.log('Tawk Failid', e);
      }
    }
  }, tawkScriptQuery.data);

  return (
    <div
      className={`min-h-screen absolute w-full -z-20 ${showTopNav ? 'bg-[#EEEEEE]' : 'bg-white'} `}
    >
      {/* {showTopNav && <Navbar />} */}
      {showTopNav && <NavBarTwo />}

      <div
        id='layoutWrapper'
        className='pt-[var(--navbar-height)] pb-[calc(var(--footer-height)+10px)]'
      >
        <Outlet />
      </div>

      <Footer />
    </div>
  );
};

export default MainLayout;
