/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  TAirlineOption,
  TFlightFilter,
  TPartOfDay,
} from '../../types/flight-filter';

import { AnimatePresence, motion } from 'framer-motion';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { formatCurrencyWithOutDecimal } from 'app/utils/currency-utils';
import { DepartureTimeEnum, StopTypeEnum } from 'app/enums';
import { assertUnreachable } from 'app/utils/common-utils';
import { TAppliedFilter } from '../../types/applied-filter';
import { PriceSlider } from './PriceSlider';

const ulAnimation = {
  variants: {
    collapsed: { opacity: 0 },
    expanded: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
      },
    },
  },
  initial: 'collapsed',
  animate: 'expanded',
};

const liAnimation = {
  variants: {
    collapsed: { opacity: 0, height: 0 },
    expanded: { opacity: 1, height: 'auto' },
  },
  initial: 'collapsed',
  animate: 'expanded',
  exit: 'collapsed',
};

interface IFilter {
  tripFilterOptions: TFlightFilter;
  appliedFilter: TAppliedFilter;
  setAppliedFilter: Dispatch<SetStateAction<TAppliedFilter>>;
}

export const Filter: FC<IFilter> = (props) => {
  const { tripFilterOptions, appliedFilter, setAppliedFilter } = props;

  const addAirlineToFilter = (airlineCode: string) => {
    setAppliedFilter((prev) => ({
      ...prev,
      airlines: [...prev.airlines, airlineCode],
    }));
  };

  const removeAirlineFromFilter = (airlineCode: string) => {
    setAppliedFilter((prev) => ({
      ...prev,
      airlines: prev.airlines.filter((code: string) => code !== airlineCode),
    }));
  };

  const addDepartureTimeToFilter = (departureTime: DepartureTimeEnum) => {
    setAppliedFilter((prev) => ({
      ...prev,
      departureTimes: [...prev.departureTimes, departureTime],
    }));
  };

  const removeDepartureTimeFromFilter = (departureTime: DepartureTimeEnum) => {
    setAppliedFilter((prev) => ({
      ...prev,
      departureTimes: prev.departureTimes.filter(
        (code: string) => code !== departureTime,
      ),
    }));
  };

  const addStopToFilter = (stop: StopTypeEnum) => {
    setAppliedFilter((prev) => ({
      ...prev,
      stops: [...prev.stops, stop],
    }));
  };

  const removeStopFromFilter = (stop: StopTypeEnum) => {
    setAppliedFilter((prev) => ({
      ...prev,
      stops: prev.stops.filter((code: string) => code !== stop),
    }));
  };

  const handlePriceLimitChange = (value: number) => {
    setAppliedFilter((prev) => ({
      ...prev,
      priceLimit: value,
    }));
  };

  //   if (!tripFilterOptions) return <></>;

  const [value, setValue] = useState(1500);

  return (
    <div>
      <div className='text-base leading-5 font-semibold'>Filter</div>
      <AirlineFilterForm
        addAirlineToFilter={addAirlineToFilter}
        removeAirlineFromFilter={removeAirlineFromFilter}
        selectedAirline={appliedFilter.airlines}
        airlineOptions={tripFilterOptions?.airlines || []}
      />
      <PriceSlider
        priceRange={{
          min: tripFilterOptions.PriceRange.min,
          max: tripFilterOptions.PriceRange.max,
        }}
        value={appliedFilter.priceLimit}
        setLimitValue={handlePriceLimitChange}
      />

      <DepartureFilterForm
        addDepartureTimeToFilter={addDepartureTimeToFilter}
        departureTimesOptions={
          tripFilterOptions?.partsOfDateCount || ({} as TPartOfDay)
        }
        removeDepartureTimeFromFilter={removeDepartureTimeFromFilter}
        selectedDepartureTimes={appliedFilter.departureTimes}
      />
      <StopFilterForm
        addStopToFilter={addStopToFilter}
        removeStopFromFilter={removeStopFromFilter}
        selectedStops={appliedFilter.stops}
        stopOptions={tripFilterOptions.stops}
      />
    </div>
  );
};

interface IAirlineFilterForm {
  airlineOptions: Array<TAirlineOption>;
  selectedAirline: Array<string>;
  removeAirlineFromFilter: (airlineCode: string) => void;
  addAirlineToFilter: (airlineCode: string) => void;
}

export const AirlineFilterForm: FC<IAirlineFilterForm> = (props) => {
  const {
    airlineOptions,
    selectedAirline,
    addAirlineToFilter,
    removeAirlineFromFilter,
  } = props;

  const [filterByAirlineListIndex, setFilterByAirlineListIndex] =
    useState<number>(3);

  const toggleFilterListIndex = () => {
    if (filterByAirlineListIndex !== 3) setFilterByAirlineListIndex(3);
    else setFilterByAirlineListIndex(airlineOptions.length);
  };

  if (!airlineOptions) return <></>;

  return (
    <div>
      <div className='text-base font-bold mt-8'>By Airline</div>

      <motion.ul className='mt-4 space-y-4' {...ulAnimation}>
        <AnimatePresence initial={false}>
          {airlineOptions.slice(0, filterByAirlineListIndex).map((row) => (
            <motion.li key={row.airline.code} {...liAnimation}>
              <div className='flex justify-between text-sm'>
                <div className='flex items-center'>
                  <input
                    className='form-checkbox'
                    type='checkbox'
                    id={row.airline.code}
                    checked={selectedAirline.includes(row.airline.code)}
                    onChange={(e: any) => {
                      if (e.target.checked)
                        addAirlineToFilter(row.airline.code);
                      else removeAirlineFromFilter(row.airline.code);
                    }}
                  />
                  <label className='ml-2' htmlFor={row.airline.code}>
                    {row.airline.name} ({row.count})
                  </label>
                </div>
                <div className='font-normal'>
                  {formatCurrencyWithOutDecimal(row.minPrice)}
                </div>
              </div>
            </motion.li>
          ))}
        </AnimatePresence>
      </motion.ul>

      {airlineOptions.length > 3 && (
        <a
          onClick={toggleFilterListIndex}
          className='text-primary text-sm leading-4 font-bold block mt-5 cursor-pointer'
        >
          {filterByAirlineListIndex === 3
            ? `+ ${airlineOptions.length - filterByAirlineListIndex} more`
            : 'Hide'}
        </a>
      )}
    </div>
  );
};

interface IDepartureFilterForm {
  departureFrom?: string;
  departureTimesOptions: TPartOfDay;
  selectedDepartureTimes: Array<DepartureTimeEnum>;
  removeDepartureTimeFromFilter: (departureTime: DepartureTimeEnum) => void;
  addDepartureTimeToFilter: (departureTime: DepartureTimeEnum) => void;
}

export const DepartureFilterForm: FC<IDepartureFilterForm> = (props) => {
  const {
    departureFrom,
    addDepartureTimeToFilter,
    removeDepartureTimeFromFilter,
    departureTimesOptions,
    selectedDepartureTimes,
  } = props;

  const getDepartureTimeLabel = (departureTime: DepartureTimeEnum): string => {
    switch (departureTime) {
      case DepartureTimeEnum.EARLY_MORNING:
        return 'Early Morning';
      case DepartureTimeEnum.MORNING:
        return ' Morning';
      case DepartureTimeEnum.AFTERNOON:
        return 'Afternoon';
      case DepartureTimeEnum.EVENING:
        return 'Evening';
      case DepartureTimeEnum.NIGHT:
        return 'Night';
      default:
        assertUnreachable(departureTime);
    }
  };

  const getDepartureTimePeriod = (departureTime: DepartureTimeEnum): string => {
    switch (departureTime) {
      case DepartureTimeEnum.EARLY_MORNING:
        return 'Midnight - 8 am';
      case DepartureTimeEnum.MORNING:
        return '8 am - Noon';
      case DepartureTimeEnum.AFTERNOON:
        return 'Noon - 4 pm';
      case DepartureTimeEnum.EVENING:
        return '4 pm - 8 pm';
      case DepartureTimeEnum.NIGHT:
        return '8 pm - Midnight';
      default:
        assertUnreachable(departureTime);
    }
  };

  return (
    <div className='mt-8'>
      <div className='text-base font-bold'>Departure Time {departureFrom}</div>

      <ul className='mt-4'>
        {(Object.keys(departureTimesOptions) as Array<DepartureTimeEnum>).map(
          (departureTimesOption) => (
            <li key={departureTimesOption}>
              <div className='flex justify-between text-sm mt-4'>
                <div className='flex items-center'>
                  <input
                    className='form-checkbox'
                    type='checkbox'
                    id={departureTimesOption}
                    value={departureTimesOption}
                    checked={selectedDepartureTimes.includes(
                      departureTimesOption,
                    )}
                    onChange={(e: any) => {
                      if (e.target.checked)
                        addDepartureTimeToFilter(departureTimesOption);
                      else removeDepartureTimeFromFilter(departureTimesOption);
                    }}
                  />
                  <label className='ml-2' htmlFor='earlyMorning'>
                    {getDepartureTimeLabel(departureTimesOption)}
                  </label>
                </div>
                <div className='font-normal text-gray-500'>
                  {getDepartureTimePeriod(departureTimesOption)}
                </div>
              </div>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};

interface IStopFilterForm {
  departureFrom?: string;
  stopOptions: Record<StopTypeEnum, number>;
  selectedStops: Array<StopTypeEnum>;
  addStopToFilter: (stop: StopTypeEnum) => void;
  removeStopFromFilter: (stop: StopTypeEnum) => void;
}

export const StopFilterForm: FC<IStopFilterForm> = (props) => {
  const {
    addStopToFilter,
    removeStopFromFilter,
    selectedStops,
    stopOptions,
    departureFrom,
  } = props;

  const getFlightStopLabel = (flightStop: StopTypeEnum): string => {
    switch (flightStop) {
      case StopTypeEnum.NON_STOP:
        return 'Non-stop';
      case StopTypeEnum.ONE_STOP:
        return '1 stop';
      case StopTypeEnum.MULTIPLE_STOP:
        return 'Multiple stops';
      default:
        assertUnreachable(flightStop);
    }
  };

  return (
    <div className='mt-8'>
      <div className='text-base font-bold'>
        {/* Stops from {departureAirport.city} */}
        Stops from {departureFrom}
      </div>
      <ul className='mt-4'>
        {(Object.keys(stopOptions) as Array<StopTypeEnum>).map((stop) => (
          <li key={stop}>
            <div className='flex items-center text-sm mt-4'>
              <input
                className='form-checkbox'
                type='checkbox'
                id={stop}
                value={stop}
                checked={selectedStops.includes(stop)}
                onChange={(e: any) => {
                  if (e.target.checked) addStopToFilter(stop);
                  else removeStopFromFilter(stop);
                }}
              />
              <label className='ml-2' htmlFor='nonstop'>
                {getFlightStopLabel(stop)} ({stopOptions[stop]})
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};


