import { TripTypeSearchFormEnum } from './../enums/trip-type.enum';
import { CabinClassEnum } from 'app/enums/cabin-class.enum';
import { assertUnreachable } from './common-utils';

export const getFormattedStops = (stops: number) => {
  if (stops <= 0) return 'Non Stop';
  else if (stops === 1) return '1 Stop';
  else return stops + ' Stops';
};

export const calculateStops = (segments: any[]) => {
  let stops = segments.length - 1;

  segments.forEach((s) => {
    stops += s.stops;
  });

  return stops;
};

export const getFormattedFareRuleKey = (key: string) => {
  const FareRuleKey: any = {
    NO_SHOW: 'No Show',
    DATECHANGE: 'Date Change Fee',
    CANCELLATION: 'Cancellation Fee',
    SEAT_CHARGEABLE: 'Seat Chargeable',
  };

  return FareRuleKey[key];
};

export const getFormattedFareTypeKey = (key: string) => {
  const FareTypeKey: any = {
    DEFAULT: 'All',
    BEFORE_DEPARTURE: 'Before Departure',
    AFTER_DEPARTURE: 'After Departure',
  };

  return FareTypeKey[key];
};

export const getFormattedRefundableType = (key: string) => {
  switch (key) {
    case 'REFUNDABLE':
      return 'Refundable';
    case 'NON_REFUNDABLE':
      return 'Non-refundable';
    case 'PARTIAL_REFUNDABLE':
      return 'Partial Refundable';
    default:
      return '-';
  }
};

export const getCabinClassLabel = (cabinClass: CabinClassEnum): string => {
  switch (cabinClass) {
    case CabinClassEnum.ALL:
      return 'All';
    case CabinClassEnum.ECONOMY:
      return 'Economy ';
    case CabinClassEnum.PREMIUM_ECONOMY:
      return 'Premium Economy';
    case CabinClassEnum.BUSINESS:
      return 'Business';
    case CabinClassEnum.FIRST:
      return 'First Class';
    case CabinClassEnum.PRIVATE:
      return 'Private';
    default:
      return assertUnreachable(cabinClass);
  }
};

export const getTripTypeLabel = (tripType: TripTypeSearchFormEnum): string => {
  switch (tripType) {
    case TripTypeSearchFormEnum.ONE_WAY:
      return 'One Way';
    case TripTypeSearchFormEnum.ROUND_TRIP:
      return 'Round Trip';
    default:
      // This default case ensures all enum values are handled
      return assertUnreachable(tripType);
  }
};
