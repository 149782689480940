export enum TripTypeEnum {
  ONE_WAY_TRIP = 'ONE_WAY_TRIP',
  ROUND_DOMESTIC_TRIP = 'ROUND_DOMESTIC_TRIP',
  ROUND_INTERNATIONAL_TRIP = 'ROUND_INTERNATIONAL_TRIP',
  MULTI_CITY_TRIP = 'MULTI_CITY_TRIP',
}

export enum TripTypeSearchFormEnum {
  ONE_WAY = 'ONE_WAY',
  ROUND_TRIP = 'ROUND_TRIP',
}