import { FC, ReactNode } from 'react';
import { IBookingPassenger } from 'app/types';
import { SHOW_DOB_ON_PRINT_TICKET } from 'app/config';
import { formatDOB, getGenderFromTitle } from 'app/utils/common-utils';

interface IPassengerDetailRow {
  passenger: IBookingPassenger;
  cabin: string;
  checkIn: string;
  passengerType: 'Adult' | 'Child' | 'Infant';
  BarCode: ReactNode;
}

const PassengerDetailRow: FC<IPassengerDetailRow> = (props) => {
  const { passenger, cabin, checkIn, passengerType, BarCode } = props;

  const renderPassengerDob = () => {
    if (!passenger.dob) return '';

    if (passengerType === 'Infant' || SHOW_DOB_ON_PRINT_TICKET)
      return ` DOB - ${formatDOB(passenger.dob, 'YYYY-MM-DD')}`;
  };

  return (
    <tr>
      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10 font-semibold'>
        <div className='flex flex-col'>
          <div>
            {passenger.title} {passenger.firstName} {passenger.lastName}
          </div>
          <div className='text-xs font-normal'>
            {getGenderFromTitle(passenger.title)}, {passengerType}
            {renderPassengerDob()}
          </div>
        </div>
      </td>
      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10 font-semibold'>
        <div className='space-y-1'>
          {passenger.pnrs.map((pnr: any, i: number) => (
            <div key={i}>
              <div>{pnr.number}</div>
              <div className='text-[0.7rem] leading-none font-normal'>
                {pnr.sector}
              </div>
            </div>
          ))}
        </div>
      </td>
      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10 font-semibold'>
        <div className='space-y-1'>
          {(passenger.ticketNumbers || []).map(
            (ticketNumber: any, i: number) => (
              <div key={i}>
                <div className='text-xs'>{ticketNumber.number}</div>
                <div className='text-[0.7rem] leading-none font-normal'>
                  {ticketNumber.sector}
                </div>
              </div>
            ),
          )}
        </div>
      </td>
      <td>
        <div className='py-3'>{BarCode} </div>
      </td>
      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10 font-semibold'>
        {cabin}
      </td>
      <td className='pt-5 px-3 align-middle first:pl-10 last:pr-10 font-semibold'>
        {checkIn}
      </td>
    </tr>
  );
};

export default PassengerDetailRow;
