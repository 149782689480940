import { FC } from 'react';
import ReturnFlights from './ReturnFlights';
import PageLoader from 'app/components/PageLoader/PageLoader';
import usePopulateSearch from './hooks/usePopulateSearch';
import BgOverlay from 'app/components/BgOverlay/BgOverlay';
import SearchFrom from './components/SearchForm/SearchFrom';
import InternationalReturn from './InternationalReturn';

interface IRoundTripSearch {}

const RoundTripSearch: FC<IRoundTripSearch> = (props) => {
  const {
    renderKey,
    isLoading,
    searchParameter,
    setNewSearchParameters,
    handleTravelDateChange,
  } = usePopulateSearch();

  return (
    <>
      {searchParameter ? (
        <div className=''>
          <BgOverlay />
          <div className='md:container'>
            <SearchFrom
              searchQuery={searchParameter}
              setNewSearchParameter={setNewSearchParameters}
            />
          </div>
          {searchParameter.from.countryCode === 'IN' &&
          searchParameter.to.countryCode === 'IN' ? (
            <ReturnFlights
              key={renderKey}
              searchQuery={searchParameter}
              handleTravelDateChange={handleTravelDateChange}
            />
          ) : (
            <InternationalReturn
              key={renderKey}
              searchQuery={searchParameter}
              handleTravelDateChange={handleTravelDateChange}
            />
          )}
        </div>
      ) : (
        <PageLoader />
      )}
    </>
  );
};

export default RoundTripSearch;
