import { TSearch } from '../types/search';
import { useEffect, useState } from 'react';
import { SearchTypeEnum } from 'app/enums/search-type.enum';
import { useQueries, useQuery } from '@tanstack/react-query';
import { TFareSupplierSearchRoute } from 'app/types/route.type';
import { FareSupplierCodesEnum } from 'app/enums/fare-supplier-codes.enum';
import { TripTypeEnum, TripTypeSearchFormEnum } from 'app/enums/trip-type.enum';
import { TFareSupplierFlightSearchRequest } from '../types/fare-supplier-search-request.type';
import {
  TMasterFareSupplierSearchResponse,
  TMasterFareSupplierSearchResult,
} from '../types/fare-supplier-search-response.type';

import {
  getAvailableFareSupplierAndSearchOptions,
  getAvailableFlights,
} from 'app/api/services/search_v2.service';

type TSearchHandler = {
  searchQuery: TSearch;
  tripType: TripTypeEnum;
};

const generateRouteInfo = (
  searchQuery: TSearch,
): Array<TFareSupplierSearchRoute> => {
  let routes: Array<TFareSupplierSearchRoute> = [];

  routes.push({
    from: {
      code: searchQuery.from.code,
    },
    to: {
      code: searchQuery.to.code,
    },
    travelDate: searchQuery.departureDate,
  });

  if (searchQuery.tripSearchType === TripTypeSearchFormEnum.ROUND_TRIP) {
    routes.push({
      from: {
        code: searchQuery.to.code,
      },
      to: {
        code: searchQuery.from.code,
      },
      travelDate: searchQuery.returnDate,
    });
  }

  return routes;
};

const createSearchBody = (
  searchQuery: TSearch,
  fsCode: FareSupplierCodesEnum,
  searchType: SearchTypeEnum,
): TFareSupplierFlightSearchRequest => {
  return {
    fareSupplier: fsCode,
    passengers: {
      adult: searchQuery.adult,
      child: searchQuery.child,
      infant: searchQuery.infant,
    },
    cabinClass: searchQuery.cabinClass,
    preferredAirlines: [],
    routeInfo: generateRouteInfo(searchQuery),
    searchType: searchType,
    filter: {
      isConnectingFlight: false,
      isDirectFlight: false,
      pft: '',
    },
    searchRequestId: '123456789012345678901234567890123456',
  };
};

const useSearchHandler = (props: TSearchHandler) => {
  const { searchQuery, tripType } = props;

  const [availableInBoundFlights, setAvailableInBoundFlights] = useState<
    Array<TMasterFareSupplierSearchResult>
  >([]);

  const [availableOutBoundFlights, setAvailableOutBoundFlights] = useState<
    Array<TMasterFareSupplierSearchResult>
  >([]);

  const [availableComboFlights, setAvailableComboFlights] = useState<
    Array<TMasterFareSupplierSearchResult>
  >([]);

  const [requestBodies, setRequestBodies] = useState<
    Array<TFareSupplierFlightSearchRequest>
  >([]);

  const { data: searchOptions, isLoading: optionLoading } = useQuery(
    ['AVAILABLE_FARE_SUPPLIERS', 'SEARCH_OPTIONS', tripType, searchQuery],
    () =>
      getAvailableFareSupplierAndSearchOptions({
        tripType,
      }),
  );

  const handleResponseExtractionAndDistribution = (
    response: TMasterFareSupplierSearchResponse,
  ) => {
    if (response?.tripInfos?.inbound) {
      setAvailableInBoundFlights((prev) => [
        ...prev,
        ...response.tripInfos.inbound,
      ]);
    }

    if (response?.tripInfos?.outbound) {
      setAvailableOutBoundFlights((prev) => [
        ...prev,
        ...response.tripInfos.outbound,
      ]);
    }

    if (response?.tripInfos?.combo) {
      setAvailableComboFlights((prev) => [
        ...prev,
        ...response.tripInfos.combo,
      ]);
    }
  };

  useEffect(() => {
    if (searchOptions) {
      let tmp: Array<TFareSupplierFlightSearchRequest> = [];
      searchOptions.forEach((searchOption) =>
        searchOption.searchTypes.forEach((searchType) => {
          tmp.push(
            createSearchBody(
              searchQuery,
              searchOption.fareSupplier,
              searchType,
            ),
          );
        }),
      );

      setRequestBodies(tmp);
    }
  }, [searchOptions, searchQuery]);

  const queries = useQueries({
    queries: requestBodies.map((requestBody, index) => ({
      queryKey: ['AVAILABLE_FLIGHT_REQUEST', index],
      queryFn: () => getAvailableFlights(requestBody),
      onSuccess: (data: TMasterFareSupplierSearchResponse) => {
        handleResponseExtractionAndDistribution(data);
      },
    })),
  });

  // const areQueriesLoading = useMemo(() => {
  //   return queries.some((qry) => qry.isLoading);
  // }, [queries, Date.now().toString]);
  // console.log({ areQueriesLoading });

  const areQueriesLoading = queries.some((qry) => qry.isLoading);

  return {
    availableComboFlights,
    availableInBoundFlights,
    availableOutBoundFlights,
    isLoading: areQueriesLoading || optionLoading,
  };
};

export default useSearchHandler;
