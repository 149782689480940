import { FC, useState } from 'react';
import { motion } from 'framer-motion';
import { ChevronIcon, RightArrowIcon } from 'app/icons';
import { assertUnreachable } from 'app/utils/common-utils';
import { FlightDirectionEnum } from 'app/enums/flight-direction.enum';
import { TFlightOptionSelected } from '../../types/FlightsList';
import { RenderFlightNumber } from '../../utils/common.utils';
import { formatTime } from 'app/utils/date-utils';
import { formatCurrency } from 'app/utils/currency-utils';
import SelectedFlightsDetail from './components/SelectedFlightsDetail';
import { useNavigate } from 'react-router-dom';
import { combinePriceItemKeys } from 'app/modules/shared/utils/common.utils';

interface ISelectedFlightOverview {
  selectedFlights: Record<FlightDirectionEnum, TFlightOptionSelected | null>;
}

const SelectedFlightOverview: FC<ISelectedFlightOverview> = (props) => {
  const {
    selectedFlights: { INBOUND, OUTBOUND },
  } = props;

  const navigate = useNavigate();

  const [showDetails, setShowDetails] = useState(false);

  const handleToggle = () => {
    if (showDetails) return;
    setShowDetails((prev) => !prev);
  };

  const handleBooking = () => {
    // add checks before allowing the booking here

    const key = combinePriceItemKeys([
      OUTBOUND.pricingDetail.id,
      INBOUND.pricingDetail.id,
    ]);
    navigate(`/v2/flights-return/${key}`);
  };

  if (!INBOUND || !OUTBOUND) return <></>;

  return (
    <div className='fixed w-full bottom-2 px-16 '>
      <motion.div
        animate={{ y: showDetails ? -2 : 0 }} // Moves up by 50px when second div is visible
        transition={{ duration: 0.5 }} // Controls speed
        className='bg-[#000D20]/90 h-32 card grid grid-cols-11 px-16 items-center gap-14'
      >
        <div className=' relative col-span-4 after:content-[" "] after:block after:h-16 after:bg-white after:w-[1px] after:absolute  after:-right-4 after:top-1/2 after:-translate-y-1/2'>
          <SelectedFlight
            flightDirection={FlightDirectionEnum.OUTBOUND}
            handleShowDetails={handleToggle}
            disableDetailsButton={showDetails}
            flight={OUTBOUND}
          />
        </div>
        <div className=' relative col-span-4 after:content-[" "] after:block after:h-16 after:bg-white after:w-[1px] after:absolute  after:-right-4 after:top-1/2 after:-translate-y-1/2'>
          <SelectedFlight
            flightDirection={FlightDirectionEnum.INBOUND}
            handleShowDetails={handleToggle}
            disableDetailsButton={showDetails}
            flight={INBOUND}
          />
        </div>
        <div className='col-span-3 flex justify-between items-center pt-4 gap-6'>
          <div className='text-white h-auto'>
            <div className='text-lg leading-5 font-bold text-white'>
              {formatCurrency(
                INBOUND.pricingDetail.fareDescription.total.totalFare +
                  OUTBOUND.pricingDetail.fareDescription.total.totalFare,
              )}
            </div>
            <div className='text-xs leading-4 font-normal text-right'>
              Total price
            </div>
          </div>
          <div className='flex gap-6'>
            <button
              onClick={handleBooking}
              className='w-32 btn bg-primary text-white text-base leading-5 font-semibold  py-2 px-6'
            >
              Book
            </button>
            <button
              className={` ${showDetails ? '' : 'rotate-180'} `}
              onClick={() => setShowDetails((prev) => !prev)}
            >
              <ChevronIcon />
            </button>
          </div>
        </div>
      </motion.div>
      {showDetails && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{
            opacity: showDetails ? 1 : 0,
            height: showDetails ? 'auto' : 0,
          }}
          transition={{
            opacity: { duration: 0.5 }, // Faster opacity transition
            height: { duration: 0.5, ease: 'easeInOut' }, // Smooth height transition for shrinking
          }}
          className='bg-white'
        >
          <div className='h-80'>
            <SelectedFlightsDetail INBOUND={INBOUND} OUTBOUND={OUTBOUND} />
          </div>
        </motion.div>
      )}
    </div>
  );
};

interface ISelectedFlight {
  flightDirection: FlightDirectionEnum;
  handleShowDetails: () => void;
  disableDetailsButton: boolean;
  flight: TFlightOptionSelected;
}

const SelectedFlight: FC<ISelectedFlight> = (props) => {
  const { flightDirection, handleShowDetails, disableDetailsButton, flight } =
    props;

  const getSectionLabel = (flightDirection: FlightDirectionEnum): string => {
    if (flightDirection === FlightDirectionEnum.OUTBOUND)
      return 'Departure Flight';
    if (flightDirection === FlightDirectionEnum.INBOUND) return 'Return Flight';
    return assertUnreachable(flightDirection);
  };

  return (
    <div className=''>
      <div className='text-xs font-normal text-white'>
        {getSectionLabel(flightDirection)}
      </div>
      <div className='grid grid-cols-12 mt-4 '>
        <div className='col-span-4'>
          <div className='flex gap-3 text-white'>
            <img
              crossOrigin='anonymous'
              className='h-9 w-9'
              // src='https://sub-agent-api-dev.farepod.co/airline-logos/6E.png'
              src={flight.Flights[0].logo}
              alt=''
            />
            <div>
              <div className='text-base leading-5 font-semibold'>
                {flight.Flights[0].name}
              </div>
              <div className='text-xs font-medium'>
                {RenderFlightNumber(flight.Flights)}
              </div>
            </div>
          </div>
        </div>

        <div className='col-span-5 items-center text-white'>
          <div className='flex gap-4'>
            <div className='text-white'>
              <div className='text-base leading-5 font-bold'>
                {formatTime(flight.from.time)}
              </div>
              <div className='text-xs leading-4 font-normal text-center'>
                {flight.from.airport.city}
              </div>
            </div>
            <div className='flex items-center'>
              <RightArrowIcon />
            </div>
            <div className='text-white'>
              <div className='text-base leading-5 font-bold'>
                {formatTime(flight.to.time)}
              </div>
              <div className='text-xs leading-4 font-normal text-center '>
                {flight.to.airport.city}
              </div>
            </div>
          </div>
        </div>

        <div className='col-span-3'>
          <div className='text-lg leading-4 font-bold text-white flex'>
            {formatCurrency(
              flight.pricingDetail.fareDescription.total.totalFare,
            )}
          </div>

          <button
            disabled={disableDetailsButton}
            onClick={handleShowDetails}
            className='text-blue-400 text-xs leading-3 font-normal '
          >
            View details
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectedFlightOverview;
