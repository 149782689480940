import { useEffect, useState } from 'react';
import {
  TAirlineOption,
  TComboFlightFilter,
  TComboFlightPartsOfDateCount,
  TStops,
} from '../../types/flight-filter';
import { TComboFlightOption, TComboOneWay } from '../../types/FlightsList';
import { FlightDirectionEnum } from 'app/enums/flight-direction.enum';

type TUseCreateComboFlightFilter = {
  flights: Array<TComboFlightOption>;
};

const initialComboFilterState: TComboFlightFilter = {
  airlines: {
    OUTBOUND: [],
    INBOUND: [],
  },
  PriceRange: {
    min: 0,
    max: 0,
  },

  stops: {
    INBOUND: {
      NON_STOP: 0,
      ONE_STOP: 0,
      MULTIPLE_STOP: 0,
    },
    OUTBOUND: {
      NON_STOP: 0,
      ONE_STOP: 0,
      MULTIPLE_STOP: 0,
    },
  },
  partsOfDateCount: {
    INBOUND: {
      AFTERNOON: 0,
      EARLY_MORNING: 0,
      EVENING: 0,
      MORNING: 0,
      NIGHT: 0,
    },
    OUTBOUND: {
      AFTERNOON: 0,
      EARLY_MORNING: 0,
      EVENING: 0,
      MORNING: 0,
      NIGHT: 0,
    },
  },
};

export const useCreateComboFlightFilter = (
  props: TUseCreateComboFlightFilter,
): TComboFlightFilter => {
  const { flights } = props;

  const [filter, setFilter] = useState<TComboFlightFilter>(
    initialComboFilterState,
  );

  useEffect(() => {
    if (flights.length === 0) {
      setFilter(initialComboFilterState);
      return;
    }

    setFilter(createComboFilter(flights));
  }, [flights]);

  return filter;
};

const createComboFilter = (
  flights: Array<TComboFlightOption>,
): TComboFlightFilter => {
  // const stops: TComboFlightStops = {}

  let minPrice: number = 0;
  let maxPrice: number = 0;

  const outBoundStops: TStops = {
    NON_STOP: 0,
    ONE_STOP: 0,
    MULTIPLE_STOP: 0,
  };

  const inBoundStops: TStops = {
    NON_STOP: 0,
    ONE_STOP: 0,
    MULTIPLE_STOP: 0,
  };

  const airlines: Record<
    FlightDirectionEnum,
    Record<string, TAirlineOption>
  > = {
    OUTBOUND: {},
    INBOUND: {},
  };
  const partsOfDateCount: TComboFlightPartsOfDateCount = {
    INBOUND: {
      AFTERNOON: 0,
      EARLY_MORNING: 0,
      EVENING: 0,
      MORNING: 0,
      NIGHT: 0,
    },
    OUTBOUND: {
      AFTERNOON: 0,
      EARLY_MORNING: 0,
      EVENING: 0,
      MORNING: 0,
      NIGHT: 0,
    },
  };

  flights.forEach((availableFlight) => {
    // creating
    partsOfDateCount.INBOUND[availableFlight.INBOUND.departureTiming]++;
    partsOfDateCount.OUTBOUND[availableFlight.OUTBOUND.departureTiming]++;

    calculateStopsForFilter(
      availableFlight.OUTBOUND.flights.length,
      outBoundStops,
    );

    calculateStopsForFilter(
      availableFlight.INBOUND.flights.length,
      inBoundStops,
    );

    generateAvailableAirlinesForFilter(
      availableFlight,
      FlightDirectionEnum.OUTBOUND,
      airlines,
    );
    generateAvailableAirlinesForFilter(
      availableFlight,
      FlightDirectionEnum.INBOUND,
      airlines,
    );

    // const airlineCode = availableFlight.INBOUND.segments[0].flight.code;

    // if (!airlines.hasOwnProperty(airlineCode)) {
    //   airlines[airlineCode] = {
    //     airline: availableFlight.INBOUND.segments[0].flight,
    //     count: 1,
    //     minPrice: availableFlight.priceList[0].fareDescription.total.totalFare,
    //     maxPrice:
    //       availableFlight.priceList[availableFlight.priceList.length - 1]
    //         .fareDescription.total.totalFare,
    //   };
    // } else {
    //   // increment to available option
    //   airlines[airlineCode].count = airlines[airlineCode].count + 1;

    //   // check and if set min price available for the airline
    //   if (
    //     airlines[airlineCode].minPrice >
    //     availableFlight.priceList[0].fareDescription.total.totalFare
    //   ) {
    //     airlines[airlineCode].minPrice =
    //       availableFlight.priceList[0].fareDescription.total.totalFare;
    //   }

    //   // check and if set man price available for the airline
    //   if (
    //     airlines[airlineCode].maxPrice <
    //     availableFlight.priceList[availableFlight.priceList.length - 1]
    //       .fareDescription.total.totalFare
    //   ) {
    //     airlines[airlineCode].maxPrice =
    //       availableFlight.priceList[
    //         availableFlight.priceList.length - 1
    //       ].fareDescription.total.totalFare;
    //   }
    // }

    // min and max price
    Object.values(airlines.OUTBOUND).forEach((airlineOption, index) => {
      if (index === 0) {
        minPrice = airlineOption.minPrice;
        maxPrice = airlineOption.maxPrice;
        return;
      }
      minPrice = Math.min(minPrice, airlineOption.minPrice);
      maxPrice = Math.max(maxPrice, airlineOption.maxPrice);
    });
  });

  return {
    airlines: {
      OUTBOUND: Object.values(airlines.OUTBOUND),
      INBOUND: Object.values(airlines.INBOUND),
    },
    // airlines: Object.values(airlines),
    PriceRange: {
      min: minPrice,
      max: maxPrice,
    },
    partsOfDateCount,
    stops: {
      OUTBOUND: outBoundStops,
      INBOUND: inBoundStops,
    },
  };
};

const generateAvailableAirlinesForFilter = (
  availableFlight: TComboFlightOption,
  flightDirection: FlightDirectionEnum,
  airlines: Record<FlightDirectionEnum, Record<string, TAirlineOption>>,
) => {
  const airlineCode = availableFlight[flightDirection].segments[0].flight.code;

  if (!airlines[flightDirection].hasOwnProperty(airlineCode)) {
    airlines[flightDirection][airlineCode] = {
      airline: availableFlight[flightDirection].segments[0].flight,
      count: 1,
      minPrice: availableFlight.priceList[0].fareDescription.total.totalFare,
      maxPrice:
        availableFlight.priceList[availableFlight.priceList.length - 1]
          .fareDescription.total.totalFare,
    };
  } else {
    // increment to available option
    airlines[flightDirection][airlineCode].count =
      airlines[flightDirection][airlineCode].count + 1;

    // check and if set min price available for the airline
    if (
      airlines[flightDirection][airlineCode].minPrice >
      availableFlight.priceList[0].fareDescription.total.totalFare
    ) {
      airlines[flightDirection][airlineCode].minPrice =
        availableFlight.priceList[0].fareDescription.total.totalFare;
    }

    // check and if set man price available for the airline
    if (
      airlines[flightDirection][airlineCode].maxPrice <
      availableFlight.priceList[availableFlight.priceList.length - 1]
        .fareDescription.total.totalFare
    ) {
      airlines[flightDirection][airlineCode].maxPrice =
        availableFlight.priceList[
          availableFlight.priceList.length - 1
        ].fareDescription.total.totalFare;
    }
  }
};



const calculateStopsForFilter = (segmentLength: number, stopsEntry: TStops) => {
  if (segmentLength === 1) {
    stopsEntry.NON_STOP = stopsEntry.NON_STOP + 1;
  } else if (segmentLength === 2) {
    stopsEntry.ONE_STOP = stopsEntry.ONE_STOP + 1;
  } else {
    stopsEntry.MULTIPLE_STOP = stopsEntry.MULTIPLE_STOP + 1;
  }
};
