import moment from 'moment';
import { TSearch } from '../types/search';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { CabinClassEnum } from 'app/enums/cabin-class.enum';
import { getAirportByCode } from 'app/api/services/airport.service';
import { TripTypeSearchFormEnum } from 'app/enums/trip-type.enum';
import { SYSTEM_DATE_TIME_FORMAT } from 'app/utils/date-utils';
import { FlightDirectionEnum } from 'app/enums/flight-direction.enum';

const usePopulateSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParameter, setSearchParameter] = useState<TSearch>(null);
  let renderKey = moment().format(SYSTEM_DATE_TIME_FORMAT);

  const fromAirportMutation = useMutation((airportCode: string) =>
    getAirportByCode(airportCode),
  );

  const toAirportMutation = useMutation((airportCode: string) =>
    getAirportByCode(airportCode),
  );

  console.log(searchParameter);

  useEffect(() => {
    const myFunction = async () => {
      const adult = +searchParams.get('adult');
      const child = +searchParams.get('child');
      const infant = +searchParams.get('infant');
      const returnDate = searchParams.get('returnDate');
      const departureDate = searchParams.get('departureDate');
      const cabinClass = searchParams.get('cabinClass') as CabinClassEnum;
      const pft = searchParams.get('pft');
      const isDirectFlight = searchParams.get(
        'isDirectFlight',
      ) as unknown as boolean;

      const [fromAirport, toAirport] = await Promise.all([
        fromAirportMutation.mutateAsync(searchParams.get('from')),
        toAirportMutation.mutateAsync(searchParams.get('to')),
      ]);

      setSearchParameter(() => ({
        adult,
        child,
        infant,
        departureDate,
        returnDate,
        cabinClass,
        pft,
        isDirectFlight,
        from: fromAirport,
        to: toAirport,
        tripSearchType: returnDate
          ? TripTypeSearchFormEnum.ROUND_TRIP
          : TripTypeSearchFormEnum.ONE_WAY,
      }));
    };
    myFunction();
  }, []);

  const setNewSearchParameters = (newData: TSearch) => {
    setSearchParameter(newData);
    searchParams.set('adult', newData.adult.toString());
    searchParams.set('child', newData.child.toString());
    searchParams.set('infant', newData.infant.toString());
    searchParams.set('departureDate', newData.departureDate);
    searchParams.set('cabinClass', newData.cabinClass);
    searchParams.set('pft', newData.pft);
    searchParams.set('from', newData.from.code);
    searchParams.set('to', newData.to.code);

    searchParams.set(
      'isDirectFlight',
      newData.isDirectFlight ? 'true' : 'false',
    );
    if (
      typeof newData?.returnDate === 'string' &&
      newData?.returnDate.length > 0
    ) {
      searchParams.set('returnDate', newData.returnDate);
    } else {
      searchParams.delete('returnDate');
    }
    setSearchParams(searchParams);
    renderKey = moment().format(SYSTEM_DATE_TIME_FORMAT);
  };

  const handleTravelDateChange = (
    direction: FlightDirectionEnum,
    newTravelDate: string,
  ) => {
    const keyName =
      FlightDirectionEnum.OUTBOUND === direction
        ? 'departureDate'
        : 'returnDate';

    setSearchParameter((prev) => ({ ...prev, [keyName]: newTravelDate }));
    searchParams.set(keyName, newTravelDate);
    setSearchParams(searchParams);
  };

  return {
    renderKey,
    searchParameter,
    setNewSearchParameters,
    handleTravelDateChange,
    isLoading: fromAirportMutation.isLoading || toAirportMutation.isLoading,
  };
};

export default usePopulateSearch;
