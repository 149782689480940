import { FC, useEffect, useState } from 'react';
import Color from 'color';

interface IFareIdentifier {
  fareIdentifier: string;
  backGroundColor?: string;
}

const FareIdentifier: FC<IFareIdentifier> = (props) => {
  const { backGroundColor = '#ECAE0E', fareIdentifier } = props;
  const [textColor, setTextColor] = useState('white');

  useEffect(() => {
    try {
      const secondaryColorObj = Color(backGroundColor);
      setTextColor(secondaryColorObj.isLight() ? 'black' : 'white');
    } catch (error) {
      console.error('Invalid color provided:', backGroundColor);
      setTextColor('white'); // Fallback to default text color
    }
  }, [backGroundColor]);

  return (
    <div className='flex'>
      <div
        style={{ background: backGroundColor, color: textColor }}
        className='rounded-md px-2 py-1 font-bold text-xs leading-[12px]'
      >
        {fareIdentifier}
      </div>
    </div>
  );
};

export default FareIdentifier;
