import { TripTypeEnum } from 'app/enums/trip-type.enum';
import http from 'app/config/http';
import { FareSupplierCodesEnum } from 'app/enums/fare-supplier-codes.enum';
import { SearchTypeEnum } from 'app/enums/search-type.enum';
import { TMasterFareSupplierSearchResult } from 'app/modules/ReturnFlights/types/fare-supplier-search-response.type';
import { TFareSupplierFlightSearchRequest } from 'app/modules/ReturnFlights/types/fare-supplier-search-request.type';

type TAvailableFareSupplierAndSearchOption = {
  fareSupplier: FareSupplierCodesEnum;
  searchTypes: Array<SearchTypeEnum>;
};

export const getAvailableFareSupplierAndSearchOptions = async (values: {
  tripType: TripTypeEnum;
}) => {
  const { data } = await http.post(
    `${process.env.REACT_APP_API_URL}/api/v2/sub-agent/flights/search-types`,
    {
      tripType: values.tripType,
    },
  );

  return data as Array<TAvailableFareSupplierAndSearchOption>;
};

export const getAvailableFlights = async (
  flightRequest: TFareSupplierFlightSearchRequest,
) => {
  const { data } = await http.post(
    `${process.env.REACT_APP_API_URL}/api/v2/sub-agent/flights`,
    {
      ...flightRequest,
    },
  );

  return data as TMasterFareSupplierSearchResult;
};
