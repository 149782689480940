import { TFlightOption } from '../types/FlightsList';

export const RenderFlightNumber = (flights: TFlightOption['Flights']) => {
  const numberOfSegments = flights.length;

  if (numberOfSegments === 1) return `${flights[0].code} ${flights[0].number}`;

  return `${flights[0].code}  ${flights[0].number}  (+${numberOfSegments - 1})`;
};


type PipelineFunction<T, U> = (input: T) => U;

export const pipeline =
  <T>(transformationSteps: PipelineFunction<any, any>[]) =>
  (input: T): any =>
    transformationSteps.reduce((value, fn) => fn(value), input);
