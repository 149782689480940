import http from 'app/config/http';

export const getReviewFlightDetails = async (tripIds: Array<string>) => {
  const { data } = await http.post(
    `${process.env.REACT_APP_API_URL}/api/v2/sub-agent/flights/review`,
    {
      priceIds: tripIds,
    },
  );
  return data;
};
