import { FC } from 'react';
import { TSearch } from '../types/search';

interface ISearchHead {
  searchQuery: TSearch;
}

const SearchHead: FC<ISearchHead> = (props) => {
  const { searchQuery } = props;
  return (
    <div className='flex justify-between items-end col-span-full mb-2'>
      <div className='text-2xl text-secondary-fg'>
        Flights from <span className='font-bold'>{searchQuery.from.city}</span>{' '}
        to
        <span className='font-bold'> {searchQuery.to.city}</span>, and back
      </div>
      <div className='text-secondary-fg text-base leading-5 font-semibold'>
        {/* {outBoundFlights.length + inBoundFlights.length} */}
        Options Found
      </div>
    </div>
  );
};

export default SearchHead;
