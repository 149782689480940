import { getDatesBetween } from 'app/utils/date-utils';
import moment, { Moment } from 'moment';
import { IDateTab } from '../types/date-tab';

export const generateDates = (
  outwardDate: Moment,
  inwardDate: Moment,
  lengthOfArray: number = 3,
): { outboundDates: Array<IDateTab>; inboundDates: Array<IDateTab> } => {
  const today = moment();

  let localOutwardDates: Array<string> = [];
  let localInwardDates: Array<string> = [];

  // handle outward dates
  if (today.isSame(outwardDate, 'day')) {
    const endDate = moment(outwardDate)
      .add(lengthOfArray - 1, 'days')
      .format('YYYYMMDD');

    localOutwardDates = getDatesBetween(
      outwardDate.format('YYYYMMDD'),
      endDate,
    );
  } else {
    let index = Math.ceil(lengthOfArray / 2) - 1;
    let isFinding = true;

    let firstDate: Moment;

    while (isFinding) {
      firstDate = outwardDate.clone().subtract(index, 'day');
      const result = firstDate.isSameOrAfter(today, 'day');

      if (result) {
        isFinding = false;
      } else {
        index--;
      }
    }
    localOutwardDates = getDatesBetween(
      firstDate.format('YYYYMMDD'),
      firstDate
        .clone()
        .add(lengthOfArray - 1, 'days')
        .format('YYYYMMDD'),
    );
  }

  // handle inward dates
  if (today.isSame(inwardDate, 'day')) {
    localInwardDates = getDatesBetween(
      today.format('YYYYMMDD'),
      today
        .clone()
        .add(lengthOfArray - 1, 'days')
        .format('YYYYMMDD'),
    );
  } else {
    let index = Math.ceil(lengthOfArray / 2) - 1;
    let isFinding = true;

    let firstDate: Moment;
    while (isFinding) {
      firstDate = inwardDate.clone().subtract(index, 'day');
      const result = firstDate.isSameOrAfter(today, 'day');

      if (result) {
        isFinding = false;
      } else {
        index--;
      }
    }
    localInwardDates = getDatesBetween(
      firstDate.format('YYYYMMDD'),
      firstDate
        .clone()
        .add(lengthOfArray - 1, 'days')
        .format('YYYYMMDD'),
    );
  }

  return {
    outboundDates: localOutwardDates.map((date) => ({
      date: moment(date, 'YYYYMMDD'),
      isSelected: moment(date, 'YYYYMMDD').isSame(outwardDate, 'day'),
      isDisabled: moment(date, 'YYYYMMDD').isAfter(inwardDate, 'day'),
      handleSelect: () => {},
    })),
    inboundDates: localInwardDates.map((date) => ({
      date: moment(date, 'YYYYMMDD'),
      isSelected: moment(date, 'YYYYMMDD').isSame(inwardDate, 'day'),
      isDisabled: moment(date, 'YYYYMMDD').isBefore(outwardDate, 'day'),
      handleSelect: () => {},
    })),
  };
};
